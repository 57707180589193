// import url from "pdfjs-dist/build/pdf.worker";

import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Toast,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import StructuredLessonContent from "../../components/StructuredLessonContent";
import StructuredLessonListGroup from "../../components/StructuredLessonListGroup";
import {
  addStructuredLessonGroup,
  deleteStructuredLessonGroup,
  getAllLessonsAndMovementBreaks,
  getAllLessonsNew,
  getLessonById,
  getLessonEquipmentById,
  getLessonEquipmentNew,
  getLessonOrMovementBreakIcon,
  getMovementBreakEquipmentById,
  getStructuredLesson,
  getStructuredLessonContent,
  giveStructuredLessonFeedback,
  structuredLessonAnalytics,
  structuredLessonWeekAnalytics,
  updateStructuredLessonGroup,
  updateStructuredLessonGroupOrder,
} from "../../firebase";
import StructuredLessonListGroupMobile from "../../components/StructuredLessonListGroupMobile";
import StructuredLessonContentMobile from "../../components/StructuredLessonContentMobile";
import { UserContext } from "../../providers/UserProvider";
import AddStructuredLessonGroupButton from "../../components/AddStructuredLessonGroupButton";
import { v4 as uuidv4 } from "uuid";
import AddLessonToStructuredLessonGroupBox from "../../components/AddLessonToStructuredLessonGroupBox";
import AddWorksheetToStructuredLessonGroupBox from "../../components/AddWorksheetToStructuredLessonGroupBox";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import pdf1 from "../../pdfs/23_24 Infants LTP.pdf";
import pdf2 from "../../pdfs/23_24 1st 2nd LTP.pdf";
import pdf3 from "../../pdfs/23_24 3rd 4th LTP.pdf";
import pdf4 from "../../pdfs/23_24 5th 6th LTP.pdf";
import pdf5 from "../../pdfs/SD Infants.pdf";
import pdf6 from "../../pdfs/SD 1st 2nd.pdf";
import pdf7 from "../../pdfs/SD 3rd 4th.pdf";
import pdf8 from "../../pdfs/SD 5th 6th.pdf";
// 2024-25 LTPs
import pdf9 from "../../pdfs/Infants LTP 24_25.pdf";
import pdf10 from "../../pdfs/1st & 2nd LTP 24_25.pdf";
import pdf11 from "../../pdfs/3rd & 4th LTP 24_25.pdf";
import pdf12 from "../../pdfs/5th & 6th LTP 24_25.pdf";

import word9 from "../../pdfs/Infants LTP 24_25.docx";
import word10 from "../../pdfs/1st & 2nd LTP 24_25.docx";
import word11 from "../../pdfs/3rd & 4th LTP 24_25.docx";
import word12 from "../../pdfs/5th & 6th LTP 24_25.docx";

import WorksheetBox from "../../components/WorksheetBox";
import WorksheetBoxMobile from "../../components/WorksheetBoxMobile";

import { PDFDocument } from "pdf-lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const UpdatedStructuredLessonPage = () => {
  let { id } = useParams();
  const { data, data2 } = useContext(UserContext);
  const [currentContent, setCurrentContent] = useState({});
  const [currentContentCopy, setCurrentContentCopy] = useState({});
  const [structuredData, setStructuredData] = useState({});
  const [structuredContent, setStructuredContent] = useState([]);
  const [listInView, setListInView] = useState(true);
  const [disableSelected, setDisableSelected] = useState(true);

  // Editing Lesson Stuff
  const [editLesson, setEditLesson] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [addLessonToggled, setAddLessonToggled] = useState(false);
  const [addWorksheetToggled, setAddWorksheetToggled] = useState(false);
  const [currentGroupName, setCurrentGroupName] = useState("");

  const [allLessons, setAllLessons] = useState([]);
  const [lessonEquipment, setLessonEquipment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(1);

  const [fortnightPageStart, setFortnightPageStart] = useState(1);
  const [fortnightPageEnd, setFortnightPageEnd] = useState(1);

  const [lessonOrder, setLessonOrder] = useState(0);

  const [score, setScore] = useState(-1);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const navigate = useNavigate();

  const structuredLessonIds = [
    "ScyF4p5IWmW0W1nN3jdu",
    "ltmdjbUAhX827TpQZrbq",
    "33w5lRGDmjeDbiZhVvuI",
    "Lr9JFFnnTg8KY5forR28",
  ];

  const goToLesson = (id) => {
    navigate(`/updated-structured-lessons/${id}`);
  };

  useEffect(() => {
    if ("Guid" in data2) {
      document.title = "Daly Exercise+ | Structured Lesson";
      if (window.innerWidth > 768) {
        document.body.style.overflow = "hidden";
      }

      const correctId = structuredLessonIds[data2.Year];

      if (id !== correctId) {
        goToLesson(correctId);
      }

      // if (year)

      // eslint-disable-next-line react-hooks/exhaustive-deps

      getStructuredLesson(id).then((res) => {
        setStructuredData(res);
        structuredLessonAnalytics(
          id,
          res.title,
          data2.Year,
          data.Guid,
          data.UserName
        );
      });

      getStructuredLessonContent(id).then((res) => {
        try {
          res.forEach((r) => {
            r.selected = false;
            r.lessons.forEach((l) => {
              l.selected = false;
            });
          });

          res[0].selected = true;
          if (res[0].lessons?.length !== 0) {
            res[0].lessons[0].selected = true;
          }

          setStructuredContent(res.sort((a, b) => a.order - b.order));
          setCurrentContent(res[0]);
          getLessonEquipment(res[0]);
          console.log(res[0])
          // Debug
          // console.log(res.sort((a, b) => a.order - b.order))

          if (res[0].plan_start_page) {
            setCurrentPage(res[0].plan_start_page);
            setPageStart(res[0].plan_start_page);
          }
          if (res[0].plan_end_page) {
            setPageEnd(res[0].plan_end_page);
          }

          setFortnightPageStart(getFortnightPageStart(res[0], res));
          setFortnightPageEnd(getFortnightPageEnd(res[0], res));
          setLessonOrder(0);
        } catch (e) {
          console.log(e);
        }
      });
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [data2, id]);

  const getPDFLink = () => {
    if (id === "8W2eNLtri3CX4XaXEnK3") {
      return pdf1;
    }
    if (id === "qWbaRb1CtKOJ406D5cm1") {
      return pdf2;
    }
    if (id === "s6GSbHwLF3lwXc4gJyxl") {
      return pdf3;
    }
    if (id === "EZsaJTyK1vFlWWD5fahY") {
      return pdf4;
    }
    if (id === "Bbf0Zc7useZEpi30MW53") {
      return pdf5;
    }
    if (id === "YwhGSkevvi82gFfiLvpD") {
      return pdf6;
    }
    if (id === "CKF13eVUjDIE0npmFPht") {
      return pdf7;
    }
    if (id === "Nyrs7VTEiaENASKcp3la") {
      return pdf8;
    }

    if (id === structuredLessonIds[0]) {
      return pdf9;
    }
    if (id === structuredLessonIds[1]) {
      return pdf10;
    }
    if (id === structuredLessonIds[2]) {
      return pdf11;
    }
    if (id === structuredLessonIds[3]) {
      return pdf12;
    }
    return pdf1;
  };

  const getWordLink = () => {
    if (id === structuredLessonIds[0]) {
      return word9;
    }
    if (id === structuredLessonIds[1]) {
      return word10;
    }
    if (id === structuredLessonIds[2]) {
      return word11;
    }
    if (id === structuredLessonIds[3]) {
      return word12;
    }
    return word9;
  };

  const lessonClicked = (content, i) => {
    setFeedbackSent(false);
    setShowFeedbackMessage(false);
    if (!editGroup) {
      unSelectAllLessons();
      setCurrentContent(content);
      getLessonEquipment(content);
      setLessonOrder(content.order);
      if (content.plan_start_page) {
        setCurrentPage(content.plan_start_page);
        setPageStart(content.plan_start_page);
      } else {
        setCurrentPage(1);
        setPageStart(1);
      }
      if (content.plan_end_page) {
        setPageEnd(content.plan_end_page);
      } else {
        setPageEnd(1);
      }

      setFortnightPageStart(getFortnightPageStart(content, structuredContent));
      setFortnightPageEnd(getFortnightPageEnd(content, structuredContent));

      const parent = document.getElementById("structured-lesson-content");
      var element = document.getElementById("lesson-" + i);
      if (i === 0) {
        element = document.getElementById("content-title");
      }
      parent.scrollTop = element.offsetTop - parent.offsetTop - 10;
      updateSelectedLessonGroup(content.id);
      updateLessonInView(i, true, content.id);
      structuredLessonWeekAnalytics(
        content.id,
        id,
        content.title,
        data2.Year,
        data.Guid,
        data.UserName
      );
    }
  };

  const mobileLessonClicked = async (content, i) => {
    setFeedbackSent(false);
    setShowFeedbackMessage(false);
    setListInView(false);
    unSelectAllLessons();
    setCurrentContent(content);
    getLessonEquipment(content);
    await delay(100);

    setFortnightPageStart(getFortnightPageStart(content, structuredContent));
    setFortnightPageEnd(getFortnightPageEnd(content, structuredContent));

    const parent = document.getElementById("structured-lesson-content");
    const element = document.getElementById("lesson-" + i);
    await delay(100);

    updateSelectedLessonGroup(content.id);
    updateLessonInView(i, true, content.id);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const updateSelectedLessonGroup = (id) => {
    const updatedLessons = structuredContent.map((data) => {
      if (data.id === id) {
        return { ...data, selected: true };
      } else {
        return { ...data, selected: false };
      }
    });
    setStructuredContent(updatedLessons);
  };

  const updateLessonInView = async (index, inView, id = "") => {
    try {
      const updatedLessons = structuredContent.map((data) => {
        if (id !== "") {
          if (
            data.id === id &&
            "lessons" in data &&
            data.lessons[index] !== undefined
          ) {
            data.lessons[index].selected = inView;
          }
        } else if (
          data.selected &&
          "lessons" in data &&
          data.lessons[index] !== undefined
        ) {
          data.lessons[index].selected = inView;
        }
        return data;
      });
      console.log(updatedLessons);
      setStructuredData({ ...structuredData, updatedLessons });
    } catch (e) {
      console.log(e);
    }
  };

  const unSelectAllLessons = () => {
    const updatedLessons = structuredContent.map((data) => {
      for (let i = 0; i < data.lessons?.length; i++) {
        data.lessons[i].selected = false;
      }

      return data;
    });
    setStructuredData({ ...structuredData, updatedLessons });
  };

  const nextLessonClicked = () => {
    let index = structuredContent.findIndex((e) => e.id === currentContent.id);
    if (index === structuredContent.length - 1) {
    } else {
      let nextContent = structuredContent[index + 1];
      lessonClicked(nextContent, 0);
    }
  };

  const nextLessonClickedMobile = () => {
    let index = structuredContent.findIndex((e) => e.id === currentContent.id);
    if (index === structuredContent.length - 1) {
    } else {
      let nextContent = structuredContent[index + 1];
      mobileLessonClicked(nextContent, 0);
    }
  };

  const clickEditLesson = () => {
    if (editLesson) {
      setEditLesson(false);
      setEditGroup(false);
      setAddLessonToggled(false);
      setAddWorksheetToggled(false);
    } else {
      setEditLesson(true);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const goBack = () => {
    navigate(-1);
  };

  const clickEditGroup = async (order) => {
    if (order !== currentContent.order && editGroup) {
      return false;
    }
    if (editGroup) {
      setEditGroup(false);
      setAddLessonToggled(false);
      setAddWorksheetToggled(false);
      await updateLessonGroup();
    } else {
      setEditGroup(true);
      await delay(200);
      setCurrentContentCopy({ ...currentContent });
    }
    return true;
  };

  const addNewGroup = async () => {
    if (groupName === "") {
      return;
    }
    const order = structuredContent.length;
    const groupId = uuidv4().toString();
    let group = {
      id: groupId,
      order: order,
      title: groupName,
      lessons: [],
    };

    let res = await addStructuredLessonGroup(id, group);
    if (!res) {
      return;
    }
    setStructuredContent([...structuredContent, group]);
  };

  const addLessonToGroup = async () => {
    if (allLessons.length === 0) {
      let all = await getAllLessonsNew();
      setAllLessons(all);
    }
    setAddLessonToggled(!addLessonToggled);
  };

  const addWorksheetToGroup = async () => {
    setAddWorksheetToggled(!addWorksheetToggled);
  };

  const saveAddedLesson = async (lesson) => {
    let icon = "icon_logo";
    let colour = "purple";
    if ("Icon" in lesson) {
      icon = lesson.Icon;
    }
    if ("Colour" in lesson) {
      colour = lesson.Colour;
    }

    const lessonDetails = {
      lesson_id: lesson.Guid,
      order: currentContent.lessons.length,
      icon: icon,
      colour: colour,
      title: lesson.Title,
    };

    var lessonCopy = {
      ...currentContent,
    };

    lessonCopy.lessons.push(lessonDetails);
    setCurrentContent(lessonCopy);
  };

  const saveAddedWorksheet = async (worksheet) => {
    worksheet = {
      ...worksheet,
      order: currentContent.lessons.length,
    };

    var lessonCopy = {
      ...currentContent,
    };

    lessonCopy.lessons.push(worksheet);
    setCurrentContent(lessonCopy);
  };

  const deleteLesson = async (order) => {
    var lessonCopy = {
      ...currentContent,
    };

    var lessonArray = lessonCopy.lessons;
    lessonArray = lessonArray.filter((l) => l.order !== order);
    lessonCopy.lessons = lessonArray.map((l, i) => {
      l.order = i;
      return l;
    });

    setCurrentContent(lessonCopy);
  };

  const updateLessonGroup = async () => {
    var structuredContentCopy = [...structuredContent];
    if (currentGroupName !== "") {
      currentContent.title = currentGroupName;
    }
    currentContent.plan_end_page = pageEnd;
    currentContent.plan_start_page = pageStart;
    setCurrentGroupName("");
    structuredContentCopy = structuredContentCopy.map((x) => {
      if (x.id === currentContent.id) {
        return currentContent;
      }
      return x;
    });

    await updateStructuredLessonGroup(id, currentContent);
    setStructuredContent(structuredContentCopy);
  };

  const getFortnightPageStart = (lesson, allLessons) => {
    try {
      const currentOrder = lesson.order;
      let position = currentOrder % 2;
      let start = 1; // Default value

      if (position === 1) {
        if (typeof lesson.plan_start_page !== "number") {
          throw new Error("Invalid plan_start_page: must be a number.");
        }
        start = lesson.plan_start_page;
      } else {
        const partnerLessonOrder = currentOrder - 1;
        const result = allLessons.find(
          (obj) => obj.order === partnerLessonOrder
        );

        if (!result || typeof result.plan_start_page !== "number") {
          throw new Error(
            `Partner lesson with order ${partnerLessonOrder} not found or missing valid plan_start_page.`
          );
        }

        start = result.plan_start_page;
      }

      return start;
    } catch (error) {
      console.error("Error in getFortnightPageStart:", error.message);
      return 1; // Default value
    }
  };

  const getFortnightPageEnd = (lesson, allLessons) => {
    try {
      const currentOrder = lesson.order;
      let position = currentOrder % 2;
      let end = 1; // Default value

      if (position === 1) {
        const partnerLessonOrder = currentOrder + 1;
        const result = allLessons.find(
          (obj) => obj.order === partnerLessonOrder
        );

        if (!result || typeof result.plan_end_page !== "number") {
          throw new Error(
            `Partner lesson with order ${partnerLessonOrder} not found or missing valid plan_end_page.`
          );
        }

        end = result.plan_end_page;
      } else {
        if (typeof lesson.plan_end_page !== "number") {
          throw new Error("Invalid plan_end_page: must be a number.");
        }
        end = lesson.plan_end_page;
      }

      return end;
    } catch (error) {
      console.error("Error in getFortnightPageEnd:", error.message);
      return 1; // Default value
    }
  };

  const cancelUpdateLessonGroup = async () => {
    setCurrentContent(currentContentCopy);
    setEditGroup(false);
    setAddLessonToggled(false);
  };

  const deleteLessonGroup = async (group) => {
    var structuredContentCopy = [...structuredContent];
    setCurrentGroupName("");
    structuredContentCopy = structuredContentCopy.filter(
      (x) => x.id !== group.id
    );

    structuredContentCopy = structuredContentCopy.map((x, i) => {
      x.order = i;
      return x;
    });

    structuredContentCopy.forEach((x) => {
      updateStructuredLessonGroup(id, x);
    });

    await deleteStructuredLessonGroup(id, group);
    setStructuredContent(structuredContentCopy);
    setCurrentContent(structuredContentCopy[0]);
    setEditGroup(false);
    setAddLessonToggled(false);
  };

  const changeLessonGroupOrder = async (direction) => {
    let order = currentContent.order;
    let newOrder = order + direction;
    if (newOrder < 0) {
      return;
    }
    if (newOrder >= structuredContent.length) {
      return;
    }

    currentContent.order = newOrder;
    currentContentCopy.order = newOrder;
    let structuredContentCopy = [...structuredContent];

    structuredContentCopy[order].order = newOrder;
    await updateStructuredLessonGroup(id, structuredContentCopy[order]);
    structuredContentCopy[newOrder].order = order;
    await updateStructuredLessonGroup(id, structuredContentCopy[newOrder]);
    structuredContentCopy.sort((a, b) => a.order - b.order);
    setStructuredContent(structuredContentCopy);
  };

  const changeLessonOrder = async (order, direction) => {
    let newOrder = order + direction;
    if (newOrder < 0) {
      return;
    }
    if (newOrder >= currentContent.lessons.length) {
      return;
    }

    let currentContentLessons = [...currentContent.lessons];
    currentContentLessons[order].order = newOrder;
    currentContentLessons[newOrder].order = order;
    currentContentLessons.sort((a, b) => a.order - b.order);

    setCurrentContent({ ...currentContent, lessons: currentContentLessons });
  };

  const getLessonEquipment = async (content) => {
    let allEquipment = [];
    let equipmentPromises = [];
    content.lessons?.forEach((lesson, i) => {
      if (!lesson.isWorksheet) {
        equipmentPromises.push(
          getLessonOrMovementBreakEquipment(lesson.lesson_id)
        );
      }
    });

    let equipmentStrings = await Promise.all(equipmentPromises);
    equipmentStrings.forEach((equipment) => {
      if (equipment) {
        console.log(equipment);
        equipment.split(" & ").forEach((value) => {
          if (!allEquipment.includes(value)) {
            allEquipment.push(value);
          }
        });
      }
    });

    setLessonEquipment(allEquipment);
  };

  const getLessonOrMovementBreakEquipment = async (id) => {
    let equipment = await getLessonEquipmentNew(id);

    return equipment;
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    // console.log(numPages);
  };

  const changeCurrentPage = (amount) => {
    if (currentPage + amount < pageStart || currentPage + amount > pageEnd) {
      return;
    }
    setCurrentPage(currentPage + amount);

    const parent = document.getElementById("structured-lesson-content");
    parent.scrollTop = 100;
  };

  const changeLessonOrderByAmount = async (newOrder) => {
    let originalOrder = currentContent.order;
    if (newOrder < 0) {
      setLessonOrder(originalOrder);
      return;
    }
    if (newOrder >= structuredContent.length) {
      setLessonOrder(originalOrder);
      return;
    }
    let structuredContentCopy = structuredContent.map((content, i) => {
      if (content.id === currentContent.id) {
        content.order = newOrder;
        updateStructuredLessonGroupOrder(id, content.id, content.order);
        return content;
      } else if (i + 1 >= newOrder) {
        content.order = i + 2;
        updateStructuredLessonGroupOrder(id, content.id, content.order);
        return content;
      } else {
        content.order = i + 1;
        updateStructuredLessonGroupOrder(id, content.id, content.order);
      }
      return content;
    });
    structuredContentCopy.sort((a, b) => a.order - b.order);
    setStructuredContent(structuredContentCopy);
    setLessonOrder(newOrder);
    setEditGroup(false);
    setAddLessonToggled(false);
    setAddWorksheetToggled(false);
  };

  const giveFeedback = async () => {
    let feedback = {
      score: score,
      user_id: data.Guid,
      user_email: data.UserName,
      lesson_id: currentContent.id,
      lesson_name: currentContent.title,
      content_group_id: id,
      content_group_name: structuredData.title,
      message: feedbackMessage,
      date: new Date().toString(),
    };
    console.log(feedback);
    console.log(structuredContent);

    await giveStructuredLessonFeedback(feedback);
    setScore(-1);
    setFeedbackMessage("");
    setShowFeedbackMessage(false);
    setFeedbackSent(true);
  };

  const giveScore = async (lessonScore) => {
    setScore(lessonScore);
    setShowFeedbackMessage(true);
    setFeedbackMessage("");
  };

  async function getPartialPdfBlob(pdfUrl, startPage = 1, endPage = 1) {
    try {
      // Fetch the PDF as an ArrayBuffer
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
  
      // Load the original PDF
      const originalPdf = await PDFDocument.load(pdfBytes);
      const totalPages = originalPdf.getPageCount();
  
      // Validate and clamp page range
      startPage = Math.max(startPage, 1); // At least 1
      endPage = Math.min(endPage, totalPages); // Within bounds
  
      // Create a new PDF document
      const newPdf = await PDFDocument.create();
  
      // Copy pages from the original PDF to the new PDF
      for (let i = startPage - 1; i < endPage; i++) {
        const [copiedPage] = await newPdf.copyPages(originalPdf, [i]);
        newPdf.addPage(copiedPage);
      }
  
      // Serialize the new PDF to bytes
      const newPdfBytes = await newPdf.save();
  
      // Create a Blob from the bytes
      const pdfBlob = new Blob([newPdfBytes], { type: "application/pdf" });
  
      return pdfBlob; // Return the Blob
    } catch (error) {
      console.error("Error creating partial PDF blob:", error);
      throw error; // Rethrow the error to let the caller handle it
    }
  }
  

  return (
    <div>
      <Container fluid className="d-none d-lg-block">
        <Row className="p-0 structured-lesson-container">
          <Col md={3} className="structured-lesson-list">
            <br />
            <h1 className="pageTitle title-margin">{structuredData.title}</h1>
            {data.Guid === "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" ? (
              <div className="edit-lesson-button-container">
                <Button
                  variant="primary"
                  className="mainButton m-3"
                  onClick={() => clickEditLesson()}
                >
                  {editLesson
                    ? "Save Structured Lesson"
                    : "Edit Structured Lesson"}
                </Button>
              </div>
            ) : (
              <></>
            )}
            <hr />
            {structuredContent?.map((item, i) => {
              return (
                <StructuredLessonListGroup
                  group={item}
                  key={i}
                  editGroup={clickEditGroup}
                  click={lessonClicked}
                  edit={editLesson}
                />
              );
            })}
            {editLesson ? (
              <div>
                <AddStructuredLessonGroupButton
                  updateVal={setGroupName}
                  saveGroup={addNewGroup}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </Col>
          <Col
            md={9}
            className="structured-lesson-content mt-0 p-0"
            id="structured-lesson-content"
          >
            {editGroup ? (
              <div className="p-3">
                <Form.Control
                  className="title-edit-form"
                  placeholder={currentContent.title}
                  onChange={(event) => setCurrentGroupName(event.target.value)}
                />

                <Row className="mt-3">
                  <Col ms={6}>
                    <Button
                      variant="danger"
                      className="button-no-colour m-3 mb-0"
                      onClick={() => deleteLessonGroup(currentContent)}
                    >
                      DELETE GROUP
                    </Button>
                  </Col>
                  <Col ms={6}>
                    <Form.Group>
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        className="title-edit-form"
                        value={lessonOrder}
                        type="number"
                        onChange={(event) =>
                          setLessonOrder(parseInt(event.target.value))
                        }
                      />
                      <Button
                        variant="danger"
                        className="mainButton ms-auto m-3 mb-0"
                        onClick={() => changeLessonOrderByAmount(lessonOrder)}
                      >
                        Save Order
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col ms={6}>
                    <Form.Group>
                      <Form.Label>Plan First Page</Form.Label>
                      <Form.Control
                        className="title-edit-form"
                        placeholder={pageStart}
                        type="number"
                        onChange={(event) => {
                          setPageStart(parseInt(event.target.value));
                          setCurrentPage(parseInt(event.target.value));
                        }}
                        value={pageStart}
                      />
                    </Form.Group>
                  </Col>
                  <Col ms={6}>
                    <Form.Group>
                      <Form.Label>Plan Last Page</Form.Label>
                      <Form.Control
                        className="title-edit-form"
                        placeholder={pageEnd}
                        value={pageEnd}
                        type="number"
                        onChange={(event) =>
                          setPageEnd(parseInt(event.target.value))
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ) : (
              <h1 className="pageTitle title-margin" id="content-title">
                {currentContent.title}
              </h1>
            )}
            <hr />
            <div className="structured-content-text-box">
              <br />
              <h2 className="structured-content-heading text-center">
                Lesson Plan
              </h2>
              <br />

              <Document
                file={getPDFLink()}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                <Page pageNumber={currentPage} />
              </Document>
              {pageEnd > pageStart ? (
                <Stack direction="horizontal">
                  <span
                    className="material-symbols-outlined plan-arrow ms-auto"
                    onClick={() => changeCurrentPage(-1)}
                  >
                    arrow_back
                  </span>
                  <span
                    className="material-symbols-outlined plan-arrow me-auto"
                    onClick={() => changeCurrentPage(1)}
                  >
                    arrow_forward
                  </span>
                </Stack>
              ) : (
                <div></div>
              )}
              <br />
              <br />
              <WorksheetBox
                worksheet={{
                  title: "Fortnightly Lesson Plan.pdf",
                  url: getPartialPdfBlob(
                    getPDFLink(),
                    fortnightPageStart,
                    fortnightPageEnd
                  ),
                  blob:true
                }}
              />
              <br />
              <WorksheetBox
                worksheet={{
                  title: "Yearly Lesson Plan.pdf",
                  url: getPDFLink(),
                }}
              />
              <br />
              <WorksheetBox
                worksheet={{ title: "Yearly Lesson Plan.docx", url: getWordLink() }}
              />
              <br />
            </div>

            <hr />

            <div className="structured-content-text-box">
              <br />
              <h2 className="structured-content-heading">Lesson Equipment</h2>
              <br />
              <div className="structured-content-text">
                You will need the following for all of the activites in this
                lesson.
              </div>
              <br />
              <ul>
                {lessonEquipment.map((value, i) => {
                  if (value != "") {
                    return (
                      <li key={i} className="structured-content-text">
                        {value}
                      </li>
                    );
                  }
                  return "";
                })}
              </ul>
            </div>
            <br />
            <hr />

            {currentContent.lessons?.map((lesson, i) => {
              return (
                <div key={i}>
                  <StructuredLessonContent
                    id={lesson.lesson_id}
                    order={lesson.order}
                    edit={editGroup}
                    updated={true}
                    elementChanging={updateLessonInView}
                    isWorksheet={lesson.strand === "Worksheets"}
                    worksheets={lesson.worksheets}
                    deleteLesson={deleteLesson}
                    movePosition={changeLessonOrder}
                    vimeo={true}
                  />
                  <hr />
                </div>
              );
            })}
            <br />
            {addWorksheetToggled ? (
              <div>
                <AddWorksheetToStructuredLessonGroupBox
                  uploadWorksheets={saveAddedWorksheet}
                />
                <hr />
              </div>
            ) : (
              <div></div>
            )}
            {addLessonToggled ? (
              <div>
                <AddLessonToStructuredLessonGroupBox
                  lessons={allLessons}
                  saveLesson={saveAddedLesson}
                />
                <hr />
              </div>
            ) : (
              <div></div>
            )}
            {editGroup ? (
              <div className="d-grid structured-content-text-box">
                <Button
                  variant="primary"
                  className="mainButton m-3"
                  onClick={() => addLessonToGroup()}
                >
                  Add Lesson
                </Button>
                <Button
                  variant="primary"
                  className="mainButton m-3"
                  onClick={() => addWorksheetToGroup()}
                >
                  Add Worksheet
                </Button>
              </div>
            ) : (
              <div>
                <div className="structured-content-text-box">
                  <br />
                  <h2 className="structured-content-heading">Feedback</h2>
                  <br />
                  {feedbackSent ? (
                    <div className="structured-content-text-em">
                      Feedback Sent!
                      <br />
                      <br />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="structured-content-text">
                    {showFeedbackMessage ? (
                      <div>
                        <Form.Group>
                          <Form.Label>
                            {`${score.toString()}/5. If you have any comments feel free to let us know!`}{" "}
                          </Form.Label>
                          <Form.Control
                            className=""
                            onChange={(event) => {
                              setFeedbackMessage(event.target.value);
                            }}
                            as="textarea"
                            rows={3}
                            value={feedbackMessage}
                          />
                        </Form.Group>
                        <Button
                          variant="primary"
                          className="mainButton p-2 mt-3"
                          onClick={() => giveFeedback()}
                        >
                          Submit
                        </Button>
                      </div>
                    ) : (
                      <div>
                        How much did you and your class enjoy this lesson?
                        <br />
                        <br />
                        <Row className="feedback-box">
                          <Col xs={2}>
                            <span
                              className="material-symbols-outlined feedback-very-bad"
                              onClick={() => giveScore(1)}
                            >
                              sentiment_very_dissatisfied
                            </span>
                          </Col>
                          <Col xs={2}>
                            {" "}
                            <span
                              className="material-symbols-outlined feedback-bad"
                              onClick={() => giveScore(2)}
                            >
                              sentiment_dissatisfied
                            </span>
                          </Col>
                          <Col xs={2}>
                            {" "}
                            <span
                              className="material-symbols-outlined feedback-neutral"
                              onClick={() => giveScore(3)}
                            >
                              sentiment_neutral
                            </span>
                          </Col>
                          <Col xs={2}>
                            {" "}
                            <span
                              className="material-symbols-outlined feedback-good"
                              onClick={() => giveScore(4)}
                            >
                              sentiment_satisfied
                            </span>
                          </Col>
                          <Col xs={2}>
                            {" "}
                            <span
                              className="material-symbols-outlined feedback-very-good"
                              onClick={() => giveScore(5)}
                            >
                              sentiment_very_satisfied
                            </span>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <br />
                  </div>
                </div>
                <hr />
                {currentContent.id ===
                structuredContent[structuredContent.length - 1]?.id ? (
                  ""
                ) : (
                  <div className="d-grid structured-content-text-box">
                    <Button
                      variant="primary"
                      className="mainButton"
                      onClick={() => nextLessonClicked()}
                    >
                      Next Lesson
                    </Button>
                  </div>
                )}
              </div>
            )}

            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <div className="d-lg-none m-3 scroll-auto">
        {listInView ? (
          <div>
            <h1 className="pageTitle title-margin">{structuredData.title}</h1>

            <hr />

            {structuredContent?.map((item, i) => {
              return (
                <StructuredLessonListGroupMobile
                  group={item}
                  key={i}
                  click={mobileLessonClicked}
                  mobile={true}
                />
              );
            })}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <div>
            <Stack
              direction="horizontal"
              className="structured-content-text-box-m"
            >
              <span
                className="material-symbols-outlined back-arrow grow"
                onClick={() => setListInView(true)}
              >
                arrow_back
              </span>
              <h1 className="pageTitle title-margin" id="content-title">
                {currentContent.title}
              </h1>
            </Stack>

            <hr className="hr-mobile" />
            <div className="structured-content-text-box-m">
              <br />
              <h2 className="structured-content-heading text-center">
                Lesson Plan
              </h2>
              <br />

              <Document
                file={getPDFLink()}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                <Page pageNumber={currentPage} />
              </Document>
              {pageEnd > pageStart ? (
                <Stack direction="horizontal">
                  <span
                    className="material-symbols-outlined plan-arrow ms-auto"
                    onClick={() => changeCurrentPage(-1)}
                  >
                    arrow_back
                  </span>
                  <span
                    className="material-symbols-outlined plan-arrow me-auto"
                    onClick={() => changeCurrentPage(1)}
                  >
                    arrow_forward
                  </span>
                </Stack>
              ) : (
                <div></div>
              )}
              <br />
              <br />
              <WorksheetBoxMobile
                worksheet={{
                  title: "Fortnightly Lesson Plan.pdf",
                  url: getPartialPdfBlob(
                    getPDFLink(),
                    fortnightPageStart,
                    fortnightPageEnd
                  ),
                  blob:true
                }}
              />
              <br />
              <WorksheetBoxMobile
                worksheet={{
                  title: "Yearly Lesson Plan.pdf",
                  url: getPDFLink(),
                }}
              />
              <br />
              <WorksheetBoxMobile
                worksheet={{ title: "Yearly Lesson Plan.docx", url: getWordLink() }}
              />
              <br />
            </div>

            <hr className="hr-mobile" />

            <div className="structured-content-text-box-m">
              <br />
              <h2 className="structured-content-heading-m">Lesson Equipment</h2>
              <br />
              <div className="structured-content-text-m">
                You will need the following for all of the activites in this
                lesson.
              </div>
              <br />
              {lessonEquipment.map((value, i) => {
                if (value !== "") {
                  return (
                    <li key={i} className="structured-content-text-m">
                      {value}
                    </li>
                  );
                }
                return "";
              })}
            </div>
            <br />
            <hr className="hr-mobile" />
            {currentContent.lessons?.map((lesson, i) => {
              return (
                <div key={i}>
                  <StructuredLessonContentMobile
                    id={lesson.lesson_id}
                    order={i}
                    updated={true}
                    vimeo={true}
                    elementChanging={updateLessonInView}
                    isWorksheet={lesson.strand === "Worksheets"}
                    worksheets={lesson.worksheets}
                  />
                  <hr className="hr-mobile" />
                </div>
              );
            })}
            <br />
            <div>
              <div className="structured-content-text-box-m">
                <br />
                <h2 className="structured-content-heading-m">Feedback</h2>
                <br />
                {feedbackSent ? (
                  <div className="structured-content-text-em-m">
                    Feedback Sent!
                    <br />
                    <br />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="structured-content-text-m">
                  {showFeedbackMessage ? (
                    <div>
                      <Form.Group>
                        <Form.Label>
                          {`${score.toString()}/5. If you have any comments feel free to let us know!`}{" "}
                        </Form.Label>
                        <Form.Control
                          className=""
                          onChange={(event) => {
                            setFeedbackMessage(event.target.value);
                          }}
                          as="textarea"
                          rows={3}
                          value={feedbackMessage}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="mainButton p-2 mt-3"
                        onClick={() => giveFeedback()}
                      >
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      How much did you and your class enjoy this lesson?
                      <br />
                      <br />
                      <Row className="feedback-box">
                        <Col xs={2}>
                          <span
                            className="material-symbols-outlined feedback-very-bad-m"
                            onClick={() => giveScore(1)}
                          >
                            sentiment_very_dissatisfied
                          </span>
                        </Col>
                        <Col xs={2}>
                          {" "}
                          <span
                            className="material-symbols-outlined feedback-bad-m"
                            onClick={() => giveScore(2)}
                          >
                            sentiment_dissatisfied
                          </span>
                        </Col>
                        <Col xs={2}>
                          {" "}
                          <span
                            className="material-symbols-outlined feedback-neutral-m"
                            onClick={() => giveScore(3)}
                          >
                            sentiment_neutral
                          </span>
                        </Col>
                        <Col xs={2}>
                          {" "}
                          <span
                            className="material-symbols-outlined feedback-good-m"
                            onClick={() => giveScore(4)}
                          >
                            sentiment_satisfied
                          </span>
                        </Col>
                        <Col xs={2}>
                          {" "}
                          <span
                            className="material-symbols-outlined feedback-very-good-m"
                            onClick={() => giveScore(5)}
                          >
                            sentiment_very_satisfied
                          </span>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <br />
                </div>
              </div>
              <hr />
              {currentContent.id ===
              structuredContent[structuredContent.length - 1]?.id ? (
                ""
              ) : (
                <div className="d-grid structured-content-text-box-m">
                  <Button
                    variant="primary"
                    className="mainButton"
                    onClick={() => nextLessonClickedMobile()}
                  >
                    Next Lesson
                  </Button>
                </div>
              )}
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatedStructuredLessonPage;
