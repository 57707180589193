import { useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Button, Stack } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getImageUrl, getLessonById } from "../firebase";
import { useInView } from "react-intersection-observer";

function WorksheetBoxMobile(props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadResource = async () => {
    try {
      if (props.worksheet.blob) {
        // If blob is true, use the Blob data in props.worksheet.url
        const blob = await props.worksheet.url;
  
        // Create an Object URL for the Blob
        const url = URL.createObjectURL(blob);
  
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        link.download = props.worksheet.title; // Specify the file name
        document.body.appendChild(link);
  
        // Trigger the download
        link.click();
  
        // Clean up the Object URL and link
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } else {
        // If blob is false, use the URL directly
        const link = document.createElement("a");
        link.href = props.worksheet.url;
        link.target = "_blank";
        link.download = props.worksheet.title;
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error downloading resource:", error);
    }
  };

  return (
    <Container fluid className="worksheet-box">
      <div className="worksheet-box-title-m">Download</div>
      <Stack
        direction="horizontal"
        className="worksheet-url-box-m"
        onClick={() => loadResource()}
      >
        <span className="material-symbols-outlined ">download</span>
        <div target="_blank">{props.worksheet.title}</div>
      </Stack>
    </Container>
  );
}

export default WorksheetBoxMobile;
