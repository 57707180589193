import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import Loading from "../../components/Loading";
import { localPixelPurchaseEvent } from "../../functions/analyticsFunctions";
import { UserContext } from "../../providers/UserProvider";

function SetupPage() {
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Setup";
    new Promise((r) => setTimeout(r, 5000)).then(() => {
      localPixelPurchaseEvent(data2.Guid);
      navigate("/manage-school/accounts");
    });
  }, [data2, navigate]);

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">
            We're just setting up your account...
          </h1>

          <br />
          <div className="w-100 d-flex align-items-center">
            <Loading center={true} />
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default SetupPage;
