import { useEffect } from "react";
import { useState } from "react";
import { Container, Button, Card, Row, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { uploadToSchedule, uploadToScheduleNew } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { getLessonNew } from "../../firebase";
import { getVideoThumbnail } from "../../helper-functions/UtilFunctions";

function UpdatedAddToSchedulePage() {
  const navigate = useNavigate();
  const [lesson, setLesson] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const { data } = useContext(UserContext);
  let { id } = useParams();
  useEffect(() => {
    document.title = "Daly Exercise+ | Add To Schedule";
    if ("UserName" in data) {
      if (Object.keys(lesson).length === 0) {
        getLessonNew(id).then((data) => {
          if (data) {
            setLesson(data);
          }
          getVideoThumbnail(data.VimeoEmbedLink).then((res) => {
            setImageUrl(res);
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const addToSchedule = async (e) => {
    let colour = "Purple";
    let image = "icon_logo";
    if ("Colour" in lesson) {
      colour = lesson.Colour;
    }
    if ("ImageUri" in lesson) {
      image = lesson.ImageUri;
    }

    var dateString = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1
    }-${startDate.getDate()}`;

    let item = {
      Title: lesson.Title,
      Guid: uuidv4().toString(),
      Date: dateString,
      Description: lesson.Description,
      ContentId: lesson.Guid,
      Colour: colour,
      ImageUri: image,
    };
    await uploadToScheduleNew(item, data.Guid, dateString);
    navigate("/schedule");
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          {/* <br /> */}
          <h1 className="pageTitle title-margin">Add to Schedule</h1>
          <br />
          <div className="d-flex justify-content-md-center m-1">
            <Card style={{ width: "40rem" }}>
              <Card.Img
                variant="top"
                src={imageUrl}
                style={{ objectFit: "cover" }}
              />
              <div className="m-3">
                <Card.Title className="text-center">
                  <h2 className="headingColour">{lesson.Title}</h2>
                </Card.Title>
                <Form.Group controlId="dob">
                  <Form.Label>
                    <b>Select Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="Date"
                    selected={startDate}
                    onChange={(e) => setStartDate(new Date(e.target.value))}
                  />
                </Form.Group>
                <br />
                <div className="d-grid">
                  <button
                    className="custom-card-button"
                    onClick={addToSchedule}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default UpdatedAddToSchedulePage;
