import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Stack,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import HomeworkPreviewPage from "../HomeworkPreviewPage";
import ExercisePreviewBox from "../../components/ExercisePreviewBox";
import ReactPlayer from "react-player";
import { getEmptyWorkout } from "../../models/workout";
import {
  addWorkoutToClassroom,
  getExerciseUrl,
  getRandomExercises,
  getRandomExercisesByYear,
} from "../../functions/workoutFunctions";

function RandomWorkoutPage() {
  const [homeworkName, setHomeworkName] = useState("");
  const [submittedName, setSubmittedName] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [nameError, setNameError] = useState("");
  const [exercises, setExercises] = useState([]);
  const [loadingExercises, setLoadingExercises] = useState(false);

  const [numberOfSets, setNumberOfSets] = useState(1);
  const [showExercise, setShowExercise] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const [gifUrl, setGifUrl] = useState("");
  const handleSets = () => {
    setNumberOfSets((numberOfSets % 3) + 1);
  };
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  const handleValidation = () => {
    let formIsValid = true;

    if (homeworkName === "") {
      setNameError("Please enter a name for your homework");
      formIsValid = false;
    }

    return formIsValid;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Random";
  }, [data2]);

  const submitRandom = async () => {
    if (handleValidation()) {
      setLoadingExercises(true);
      let random = await getRandomExercises(
        numberOfExercises
      );
      setSubmittedName(homeworkName);
      setExercises(random);
    }
    setLoadingExercises(false);
  };

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const saveWorkout = async () => {
    let workout = getEmptyWorkout();
    workout = {
      ...workout,
      Thumbnail: `homework/thumbnails/${exercises[0].GifUri}.jpg`,
      Name: submittedName,
      Exercises: exercises.map((e) => e.Guid),
      Sets: numberOfSets,
    };

    await addWorkoutToClassroom(data2.Guid, workout);
    navigate("/workouts/saved");
  };

  return (
    <Container fluid>
      <Modal
        show={showExercise}
        onHide={handleCloseExercise}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {exerciseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactPlayer url={gifUrl} loop={true} playing={true} muted={true} className="exercise-preview-video"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExercise}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Random Workout</h1>
          <div className="white-box">
            <Stack direction="horizontal">
              <h3 className="title-margin page-title">
                Create a new random workout
              </h3>
              <h4 className="ms-auto">{submittedName}</h4>
            </Stack>
          </div>

          <Row>
            <Col md={8}>
              <div className="white-box equal-height">
                {loadingExercises ? (
                  <div className="custom-loader" />
                ) : exercises.length === 0 ? (
                  <></>
                ) : (
                  <div>
                    <Stack direction="horizontal">
                      <h3 className="headingColour">
                        <b>Exercises</b>
                      </h3>
                      <button
                        type="button"
                        className="ms-auto custom-button-big"
                        onClick={handleSets}
                      >
                        Sets x{numberOfSets}
                      </button>
                    </Stack>
                    {exercises.map((x, i) => {
                      return (
                        <ExercisePreviewBox
                          exercise={x}
                          key={i}
                          PreviewExercise={changeSelectedExercise}
                        />
                      );
                    })}
                    <hr />
                    <div className="d-grid">
                      <button
                        type="button"
                        className="custom-button-big custom-button-variant-good"
                        onClick={saveWorkout}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>

            <Col md={4}>
              <div className="white-box equal-height">
                <h4>Fill out to view a preview!</h4>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Workout Name</Form.Label>
                    <Form.Control
                      placeholder="Enter name"
                      aria-desscribedby="homeworkNameHelpBlock"
                      onChange={(event) => setHomeworkName(event.target.value)}
                    />
                    <Form.Text id="homeworkNameHelpBlock" muted>
                      {nameError}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Number of Exercises</Form.Label>
                    <Form.Select
                      onChange={(event) =>
                        setNumberOfExercises(event.target.value)
                      }
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                    </Form.Select>
                  </Form.Group>
                  <hr />
                  <div className="d-grid">
                    <button
                      onClick={submitRandom}
                      className="custom-button-big"
                      type="button"
                    >
                      Generate Workout
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default RandomWorkoutPage;
