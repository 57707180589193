// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getFunctions, httpsCallable } from "firebase/functions";

import { getAnalytics, logEvent } from "firebase/analytics";

import { v4 as uuidv4 } from "uuid";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  getDatabase,
  remove,
  ref,
  get,
  child,
  update,
  set,
  push,
} from "firebase/database";

import {
  getStorage,
  getDownloadURL,
  ref as sRef,
  uploadBytes,
} from "firebase/storage";

import {
  collection,
  doc,
  addDoc,
  getDoc,
  onSnapshot,
  getDocs,
  getFirestore,
  query,
  where,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  tempCategoryData,
  tempDataLessons,
  tempLessonData2,
  tempSubcategoryData,
  warmUpVideos,
} from "./temp-data/tempJSON";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBX_aUdDCK3vxph1SLilCsNpiMFMDecRVk",

  authDomain: "dalyfitness-3abef.firebaseapp.com",

  databaseURL: "https://dalyfitness-3abef.firebaseio.com",

  projectId: "dalyfitness-3abef",

  storageBucket: "dalyfitness-3abef.appspot.com",

  messagingSenderId: "331176802069",

  appId: "1:331176802069:web:6c8bed0260a3baa31c1b1c",

  measurementId: "G-2GY7Z3TC1K",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export const firestoreDb = getFirestore(app);

const database = getDatabase();

export const storage = getStorage();

export const functions = getFunctions();

export const auth = getAuth(app);

export async function getUserData(userId) {
  const dbRef = ref(database);
  return get(child(dbRef, `users/${userId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return null;
    });
}

export async function getClassData(classId) {
  const dbRef = ref(database);
  return get(child(dbRef, `classes/${classId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getClassroomStudentsFromList(studentList) {
  const dbRef = ref(database);

  if (!studentList || studentList.length === 0) {
    return [];
  }

  let requestList = [];

  studentList.forEach((id) => {
    requestList.push(
      get(child(dbRef, `users/${id}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            return data;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.error(error);
          return null;
        })
    );
  });

  return await Promise.all(requestList).then((values) => {
    return values;
  });
}

export async function getAllLessons() {
  const dbRef = ref(database);
  return get(child(dbRef, `lessons`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getAllExercises() {
  const dbRef = ref(database);
  return get(child(dbRef, `exercises`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getAllMovementBreaks() {
  const dbRef = ref(database);
  return get(child(dbRef, `movementBreaks`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getMovementBreakCategories() {
  const dbRef = ref(database);
  return get(child(dbRef, `movementBreakCategories`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getImageUrl(imagePath) {
  return getDownloadURL(sRef(storage, imagePath))
    .then((url) => {
      return url;
    })
    .catch((error) => {});
}

export async function getStrandsByYear(year) {
  const dbRef = ref(database);
  return get(child(dbRef, `strands`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data
          .filter((strand) => {
            return strand.Year === year;
          })
          .sort((a, b) => b.StrandNumber <= a.StrandNumber);
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getAllStrands() {
  const dbRef = ref(database);
  return get(child(dbRef, `strands`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function getAllUnits() {
  const dbRef = ref(database);
  return get(child(dbRef, `strandUnits`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function orderUnits() {
  let units = await getAllUnits();
  const dbRef = ref(database);

  units.forEach((unit) => {
    update(child(dbRef, `orderedUnits/${unit.StrandGuid}/${unit.Guid}`), unit);
  });
}

export async function orderLessons() {
  let lessons = await getAllLessons();
  const dbRef = ref(database);

  lessons.forEach((lesson) => {
    update(
      child(dbRef, `orderedLessons/${lesson.UnitGuid}/${lesson.Guid}`),
      lesson
    );
  });
}

export async function orderLessonsById() {
  let lessons = await getAllLessons();
  const dbRef = ref(database);

  lessons.forEach((lesson) => {
    update(child(dbRef, `orderedLessonsById/${lesson.Guid}`), lesson);
  });
}

export async function orderMovementBreaks() {
  let lessons = await getAllMovementBreaks();
  const dbRef = ref(database);

  lessons.forEach((lesson) => {
    update(
      child(
        dbRef,
        `orderedMovementBreaks/${lesson.CategoryGuid}/${lesson.Year}/${lesson.Guid}`
      ),
      lesson
    );
  });
}

export async function orderMovementBreaksById() {
  let lessons = await getAllMovementBreaks();
  const dbRef = ref(database);

  lessons.forEach((lesson) => {
    update(child(dbRef, `orderedMovementBreaksById/${lesson.Guid}`), lesson);
  });
}

export async function orderExercises() {
  let lessons = await getAllExercises();
  const dbRef = ref(database);

  lessons.forEach((lesson) => {
    update(
      child(dbRef, `orderedExercises/${lesson.Category}/${lesson.Guid}`),
      lesson
    );
  });
}

export async function getStrandsUnits(strandId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedUnits/${strandId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getUnitLessons(unitId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedLessons/${unitId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getLessonById(lessonId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedLessonsById/${lessonId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getMovementBreaksByCategory(categoryId, year) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedMovementBreaks/${categoryId}/${year}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getMovementBreakById(lessonId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedMovementBreaksById/${lessonId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function uploadToSchedule(item, userId, year, month, day) {
  const dbRef = ref(database);
  update(
    child(
      dbRef,
      `schedule/${userId}/Year-${year}/Month-${month}/Day-${day}/${item.Guid}`
    ),
    item
  );
}

export async function getDaySchedule(userId, date) {
  const dbRef = ref(database);
  return get(
    child(
      dbRef,
      `schedule/${userId}/Year-${date.getFullYear()}/Month-${
        date.getMonth() + 1
      }/Day-${date.getDate()}`
    )
  )
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getStrandDetailsFromUnitId(unitId, year) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedUnits/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        var parsed = Object.entries(data);
        var strandId = parsed.find((val) => unitId in val[1])[0];
        return getStrandsByYear(year).then((val) => {
          var strand = val.find((o) => o.Guid === strandId);
          if (!strand) {
            return getEventStrandsByYear(year).then((val2) => {
              var strand = val2.find((o) => o.Guid === strandId);
              return {
                ColourName: strand.ColourName,
                ImageUri: strand.ImageUri,
              };
            });
          }
          return {
            ColourName: strand.ColourName,
            ImageUri: strand.ImageUri,
          };
        });
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getMBCategoryDetailsFromId(categoryGuid) {
  return getMovementBreakCategories()
    .then((result) => {
      var category = result.find((c) => c && c.Guid === categoryGuid);
      return {
        ColourName: category.ColourName,
        ImageUri: category.IconName,
      };
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function removeItemFromSchedule(userId, itemId, date) {
  const dbRef = ref(database);
  return remove(
    child(
      dbRef,
      `schedule/${userId}/Year-${date.getFullYear()}/Month-${
        date.getMonth() + 1
      }/Day-${date.getDate()}/${itemId}`
    )
  ).catch((error) => {
    console.error(error);
    return {};
  });
}

export async function getAdminDetails(userId) {
  const dbRef = ref(database);
  return get(child(dbRef, `admin/${userId}/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getInviteCodeDetails(inviteCode) {
  const dbRef = ref(database);
  return get(child(dbRef, `inviteCodes/${inviteCode}/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export async function getUserEmail(userId) {
  const dbRef = ref(database);
  return get(child(dbRef, `users/${userId}/UserName`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return "";
      }
    })
    .catch((error) => {
      console.error(error);
      return "";
    });
}

export async function getSubscriptionDetails(userId) {
  const q = query(
    collection(firestoreDb, `customers/${userId}/subscriptions`),
    where("status", "in", ["trialing", "active"])
  );
  return getDocs(q)
    .then((snapshot) => {
      if (snapshot.docs[0]) {
        let doc = snapshot.docs[0].data();
        let docId = snapshot.docs[0].id;
        return {
          id: docId,
          subscriptionID: doc.items[0].price.id,
          status: doc.status,
          quantity: doc.items[0].quantity,
          price: doc.items[0].price.unit_amount,
          interval: doc.items[0].price.recurring.interval,
          created: doc.date ? doc.date : doc.created,
          isFree: doc.free,
          nextPaymentDue: doc.current_period_end,
        };
      }
      return {};
    })
    .catch((error) => {
      return {};
    });
}

export async function manageSubscription() {
  const manageSub = httpsCallable(
    functions,
    "ext-firestore-stripe-subscriptions-createPortalLink"
  );
  try {
    const { data } = await manageSub({
      returnUrl: window.location.href,
      locale: "auto",
    });
    return data.url;
  } catch (e) {
    // console.log(e);
    return "";
  }
}

export async function addCodeToUser(code, userEmail) {
  const addCodeFunction = httpsCallable(functions, "addUserToCode");
  let response = await addCodeFunction({
    code: code,
    email: userEmail,
  });
  return response.data;
}

export async function removeCodeFromUser(code) {
  const addCodeFunction = httpsCallable(functions, "removeUserFromCode");
  let response = await addCodeFunction({
    code: code,
  });
  return response.data;
}

export async function createUser(user) {
  const dbRef = ref(database);
  if (user === null) {
    return null;
  }

  if (await checkIfUserHasAClass(user)) {
    return null;
  }

  const uid = user.uid;
  const email = user.email;
  const classId = uuidv4().toString();
  let teacher = {
    Guid: uid,
    UserName: email,
    Role: "Teacher",
    ClassId: classId,
    Year: 0,
    TrialExpireyDate: new Date(2030, 1, 1).yyyymmdd(),
    IsAdmin: true,
  };

  let classroom = {
    guid: classId,
    teachers: [uid],
    students: [],
  };

  let teacherDone = await set(child(dbRef, `users/${uid}`), teacher)
    .then(() => true)
    .catch((error) => {
      return false;
    });
  let classDone = await set(child(dbRef, `classes/${classId}`), classroom)
    .then(() => true)
    .catch((error) => {
      return false;
    });

  if (teacherDone && classDone) {
    return teacher;
  }
  return null;
}

async function checkIfUserHasAClass(user) {
  const dbRef = ref(database);
  if (user === null) {
    return true;
  }
  const uid = user.uid;
  return get(child(dbRef, `users/${uid}/ClassId`))
    .then((snapshot) => {
      return snapshot.exists();
    })
    .catch((error) => {
      console.error(error);
      return true;
    });
}

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    "-",
    (mm > 9 ? "" : "0") + mm,
    "-",
    (dd > 9 ? "" : "0") + dd,
  ].join("");
};

export async function UploadClassName(classId, className) {
  const dbRef = ref(database);
  return await update(child(dbRef, `classes/${classId}`), { Name: className })
    .then(() => true)
    .catch((error) => {
      return false;
    });
}

export async function UploadUserYear(id, year) {
  const dbRef = ref(database);
  return await update(child(dbRef, `users/${id}`), { Year: year })
    .then(() => true)
    .catch((error) => {
      return false;
    });
}

export async function moveToCheckout(itemId, userId, numOfItems) {
  let q = await addDoc(
    collection(firestoreDb, `customers/${userId}/checkout_sessions`),
    {
      price: itemId,
      success_url: "https://dalyexerciseplus.ie/setup",
      allow_promotion_codes: true,
      quantity: numOfItems,
      trial_from_plan: true,
      mode: "subscription",
      trial_period_days: 7,
      subscription_data: {
        trial_period_days: 7,
        trial_settings: {
          end_behavior: {
            missing_payment_method: "cancel",
          },
        },
      },
    }
  );

  onSnapshot(q, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url);
    }
  });
}

export async function UploadAdminSchoolDetails(details, guid) {
  const dbRef = ref(database);
  await update(child(dbRef, `users/${guid}`), { IsAdmin: true });
  return await update(child(dbRef, `admin/${guid}`), details)
    .then(() => true)
    .catch((error) => {
      return false;
    });
}

export async function createCodes(codeCount, userId) {
  const addCodeFunction = httpsCallable(functions, "createInviteCode");
  let response = await addCodeFunction({
    codeCount: codeCount,
    userId: userId,
  });
  return response.data;
}

export async function makeAllAdminsAdmin() {
  const dbRef = ref(database);
  let admins = await get(child(dbRef, `admin`));
  Object.keys(admins.val()).forEach((key) => {
    update(child(dbRef, `users/${key}`), {
      IsAdmin: true,
    });
  });
}

export async function removeCodes(totalCodes, userId) {
  const addCodeFunction = httpsCallable(functions, "removeInviteCodes");
  let response = await addCodeFunction({
    totalCodes: totalCodes,
    userId: userId,
  });
  return response.data;
}

export async function GetClassCode(classId) {
  const dbRef = ref(database);
  let classCode = await get(child(dbRef, `classes/${classId}/code`));
  if (classCode.exists()) {
    if (!VerifyCodeDate(classCode.val())) {
      await RemoveClassCodeToDatabase(classCode.val().Code);
      let code = makeCode(5);
      while (await CheckIfClassCodeExists(code)) {
        code = makeCode(5);
      }
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getFullYear() + 100);

      let newCode = {
        Code: code,
        ExpireryDate: tomorrow,
        ClassId: classId,
      };

      await AddClassCodeToClassroom(classId, newCode);
      await AddClassCodeToDatabase(newCode);
      return newCode;
    }
    return classCode.val();
  } else {
    let code = makeCode(5);
    while (await CheckIfClassCodeExists(code)) {
      code = makeCode(5);
    }
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getFullYear() + 100);

    let newCode = {
      Code: code,
      ExpireryDate: tomorrow,
      ClassId: classId,
    };

    await AddClassCodeToClassroom(classId, newCode);
    await AddClassCodeToDatabase(newCode);
    return newCode;
  }
}

function AddClassCodeToClassroom(classId, code) {
  const dbRef = ref(database);
  return update(child(dbRef, `classes/${classId}/code`), code);
}

function AddClassCodeToDatabase(code) {
  const dbRef = ref(database);
  return update(child(dbRef, `codes/${code.Code}/`), code);
}

function RemoveClassCodeToDatabase(code) {
  const dbRef = ref(database);
  return remove(child(dbRef, `codes/${code}/`));
}

function VerifyCodeDate(code) {
  if (code === null) {
    return false;
  }
  return new Date() <= new Date(code.ExpireryDate);
}

function makeCode(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function CheckIfClassCodeExists(code) {
  const dbRef = ref(database);
  let classCode = await get(child(dbRef, `codes/${code}`));
  return classCode.exists();
}

export async function addStudentAsTeacher(
  firstname,
  surname,
  password,
  classId
) {
  const addCodeFunction = httpsCallable(functions, "createStudent");
  let response = await addCodeFunction({
    firstname: firstname,
    surname: surname,
    password: password,
    classId: classId,
  });
  return response.data;
}

export async function removeStudentFromClass(studentId, classId) {
  const addCodeFunction = httpsCallable(functions, "deleteUserCallable");
  let response = await addCodeFunction({
    studentId: studentId,
    classId: classId,
  });
  return response.data;
}

export async function resetStudentPassword(studentId, password) {
  const addCodeFunction = httpsCallable(functions, "changeUserPasswordCall");
  let response = await addCodeFunction({
    studentId: studentId,
    newPassword: password,
  });
  return response.data;
}

export async function GetTeacherHomework(classId) {
  try {
    const dbRef = ref(database);
    let homework = (
      await get(child(dbRef, `classes/${classId}/homework`))
    ).val();
    if (!homework) {
      return { scheduled: [], history: [] };
    }
    let scheduledHomework = [];
    let historyHomework = [];

    Object.values(homework).forEach((assignment) => {
      let finished = 0;
      if ("Status" in assignment) {
        Object.values(assignment.Status).forEach((status) => {
          if (status.Status) {
            finished++;
          }
        });
        if (new Date(assignment.DueDate) > new Date()) {
          scheduledHomework.push({
            DueDate: assignment.DueDate,
            Guid: assignment.Guid,
            Title: assignment.Title,
            Completed: `${finished}/${Object.keys(assignment.Status).length}`,
          });
        } else {
          historyHomework.push({
            DueDate: new Date(assignment.DueDate),
            Guid: assignment.Guid,
            Title: assignment.Title,
            Completed: `${finished}/${Object.keys(assignment.Status).length}`,
          });
        }
      } else {
        if (new Date(assignment.DueDate) > new Date()) {
          scheduledHomework.push({
            DueDate: assignment.DueDate,
            Guid: assignment.Guid,
            Title: assignment.Title,
            Completed: `0`,
          });
        } else {
          historyHomework.push({
            DueDate: new Date(assignment.DueDate),
            Guid: assignment.Guid,
            Title: assignment.Title,
            Completed: `0`,
          });
        }
      }
    });
    scheduledHomework.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.DueDate) - new Date(b.DueDate);
    });
    historyHomework.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.DueDate) - new Date(a.DueDate);
    });
    return { scheduled: scheduledHomework, history: historyHomework };
  } catch (e) {
    console.log(e);
    return { scheduled: [], history: [] };
  }
}

export async function getHomeworkFromId(classId, guid) {
  const dbRef = ref(database);
  return (await get(child(dbRef, `classes/${classId}/homework/${guid}`))).val();
}

export async function getRandomExercisesByYear(year, numOfRandom) {
  let allExercises = await getAllExercises();
  let yearExercises = allExercises.filter((x) => x.Year === year);
  return yearExercises
    .sort((a, b) => 0.5 - Math.random())
    .slice(0, numOfRandom);
}

export async function getExerciseUrl(name) {
  return await getDownloadURL(sRef(storage, name));
}

export async function uploadHomework(
  dueDate,
  classId,
  exerciseGuids,
  title,
  imageUri,
  sets,
  studentGuids
) {
  let id = uuidv4().toString();
  let homeworkStatus = {};
  studentGuids.forEach((x) => {
    homeworkStatus[x] = {
      Feedback: "",
      Status: false,
      TraffilLight: "Off",
    };
  });

  let homeworkUpload = {
    Guid: id,
    DueDate: dueDate,
    Status: homeworkStatus,
    Title: title,
    ExerciseGuids: exerciseGuids,
    ImageUri: imageUri,
    Sets: sets,
  };

  const dbRef = ref(database);
  await update(
    child(dbRef, `classes/${classId}/homework/${id}`),
    homeworkUpload
  );
}

export async function getAllExercisesByYear(year) {
  const dbRef = ref(database);
  let allExercises = (await get(child(dbRef, `orderedExercises`))).val();
  for (const [key, value] of Object.entries(allExercises)) {
    for (const [key2, value2] of Object.entries(value)) {
      if (value2.Year !== year) {
        delete allExercises[key][key2];
      }
    }
  }
  return allExercises;
}

export async function getAllDEHomeworkByYear(year) {
  const dbRef = ref(database);
  let allExercises = (await get(child(dbRef, `homework`)))
    .val()
    .filter((x) => x.Year === year);
  let everything = await getAllExercises();
  allExercises.map((x) => {
    let ids = x.ExerciseGuids.split("+");
    x.Exercises = ids.map((id) => {
      return everything.filter((y) => y.Guid === id)[0];
    });
  });
  return allExercises;
}

export async function getExerciseById(id) {
  let allExercises = (await getAllExercises()).filter((x) => x.Guid === id);
  return allExercises[0];
}

export async function getExercisesFromList(list) {
  let everything = await getAllExercises();
  let ids = list.ExerciseGuids.split("+");
  return ids.map((id) => {
    return everything.filter((y) => y.Guid === id)[0];
  });
}

export async function giveStudentFeedback(guid, classId, feedback, homeworkId) {
  const dbRef = ref(database);
  return update(
    child(dbRef, `classes/${classId}/homework/${homeworkId}/Status/${guid}/`),
    { Feedback: feedback }
  );
}

export async function getRandomLessonByYear(year, max) {
  let allLessons = await getAllLessons();
  return allLessons.sort((a, b) => 0.5 - Math.random()).slice(0, max);
}

export async function getRandomMovementBreakByYear() {
  let allLessons = await getAllMovementBreaks();
  return allLessons.sort((a, b) => 0.5 - Math.random()).slice(0, 4);
}

export async function getAdminEmails() {
  const getAdminDetails = httpsCallable(
    functions,
    "getRecentPaidAccountEmails"
  );
  getAdminDetails().then((result) => {
    if (result.data.Message !== "Error incorrect credentials") {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Email,CreationDate,School,Area,County,Codes,IsAdmin\r\n";

      result.data.Message.forEach((row) => {
        if (row !== null) {
          let date = new Date(row.creationDate);
          let dateString =
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear();
          csvContent +=
            row.email +
            "," +
            dateString +
            "," +
            row.school.replaceAll(",", " ") +
            "," +
            row.area.replaceAll(",", " ") +
            "," +
            row.county.replaceAll(",", " ") +
            "," +
            row.codes +
            "," +
            row.isAdmin +
            "\r\n";
        }
      });
      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "paid_account_emails.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  });
}

export async function getIPPNEmails() {
  const getAdminDetails = httpsCallable(
    functions,
    "getRecentIPPNAccountEmails"
  );
  getAdminDetails().then((result) => {
    if (result.data.Message !== "Error incorrect credentials") {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Email,CreationDate,School,Area,County,Codes,IsAdmin\r\n";

      result.data.Message.forEach((row) => {
        if (row !== null) {
          let date = new Date(row.creationDate);
          let dateString =
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear();
          csvContent +=
            row.email +
            "," +
            dateString +
            "," +
            row.school.replaceAll(",", " ") +
            "," +
            row.area.replaceAll(",", " ") +
            "," +
            row.county.replaceAll(",", " ") +
            "," +
            row.codes +
            "," +
            row.isAdmin +
            "\r\n";
        }
      });
      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "ippn_account_emails.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  });
}

export async function getDeletedSubscriptions() {
  const getAdminDetails = httpsCallable(
    functions,
    "getRecentSubscriptionEndedAccounts"
  );
  getAdminDetails().then((result) => {
    if (result.data.Message !== "Error incorrect credentials") {
      let data = result.data.Message;
      let csvContent = "data:text/csv;charset=utf-8,";
      data.forEach((row) => {
        csvContent +=
          row.school + "," + row.email + "," + row.creationDate + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "subscription_ended_account_emails.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  });
}

export async function deleteScheduledHomework(classId, homeworkId) {
  const dbRef = ref(database);
  return remove(child(dbRef, `classes/${classId}/homework/${homeworkId}/`));
}

export async function resetUserPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (e) {
    return false;
  }
}

export async function getEventStrandsByYear(year) {
  const dbRef = ref(database);
  return get(child(dbRef, `limitedLessons/strands`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data.filter((strand) => {
          return strand.Year === year;
        });
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function getEventUnitsByStrandId(id) {
  const dbRef = ref(database);
  return get(child(dbRef, `limitedLessons/units`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data.filter((unit) => {
          return unit.StrandGuid === id;
        });
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function organiseEventLessons() {
  const dbRef = ref(database);
  return get(child(dbRef, `limitedLessons/lessons`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        data.forEach((lesson) => {
          update(
            child(dbRef, `orderedLessons/${lesson.UnitGuid}/${lesson.Guid}`),
            lesson
          );
        });
        data.forEach((lesson) => {
          update(child(dbRef, `orderedLessonsById/${lesson.Guid}`), lesson);
        });
      } else {
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getEventLessonsByUnitId(id) {
  const dbRef = ref(database);
  return get(child(dbRef, `limitedLessons/lessons`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data.filter((lesson) => {
          return lesson.UnitGuid === id;
        });
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function organizeEventUnits() {
  const dbRef = ref(database);
  get(child(dbRef, `limitedLessons/units`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        data.forEach((unit) => {
          update(
            child(dbRef, `orderedUnits/${unit.StrandGuid}/${unit.Guid}`),
            unit
          );
        });
      } else {
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function getChristmasGames(year) {
  const dbRef = ref(database);
  return get(child(dbRef, `limitedLessons/lessons`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let yearExercises = data.filter(
          (x) => x.UnitGuid === "e828e211-b7b7-47db-9180-bcfb979693b8"
        );
        return yearExercises.sort((a, b) => 0.5 - Math.random()).slice(0, 2);
      } else {
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getClassWithName(name) {
  const dbRef = ref(database);
  return get(child(dbRef, `classes`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();

        let classes = Object.values(data).filter(
          (x) => x.Name && x.Name.match(name)
        );
        return classes;
      } else {
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function verifyInviteCode(code, id) {
  return true;
  if (id === "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2") {
    return true;
  }
  if (code === "" || !code) {
    return false;
  }
  let inviteCode = await getInviteCodeDetails(code);
  if (inviteCode === {}) {
    return false;
  }
  if (!Object.hasOwn(inviteCode, "TeacherGuid")) {
    return false;
  }
  return inviteCode.TeacherGuid === id;
}

export async function getNews(limit = -1) {
  const dbRef = ref(database);
  return get(child(dbRef, `news`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const list = Object.values(data);
        return list.sort((a, b) => a.timestamp > b.timestamp);
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export async function addNews(news) {
  const dbRef = ref(database);
  return update(child(dbRef, `news/${news.id}`), news)
    .then((snapshot) => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export async function deleteNews(id) {
  const dbRef = ref(database);
  return remove(child(dbRef, `news/${id}`))
    .then((snapshot) => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export async function getStructuredLessonContent(id) {
  let query = collection(firestoreDb, `structured-lessons/${id}/content`);
  const querySnapshot = await getDocs(query);
  let doc = querySnapshot.docs
    .map((val) => {
      return val.data();
    })
    .sort((a, b) => a.order > b.order);
  return doc;
}

export async function getStructuredLesson(id) {
  try {
    let query = doc(firestoreDb, `structured-lessons`, `${id}`);
    let sl = await getDoc(query);
    if (sl.exists()) {
      return sl.data();
    }
    return {};
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function addStructuredLessonGroup(id, group) {
  try {
    let query = doc(firestoreDb, `structured-lessons/${id}/content`, group.id);
    let res = await setDoc(query, group);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function updateStructuredLessonGroup(id, group) {
  try {
    let query = doc(firestoreDb, `structured-lessons/${id}/content`, group.id);
    let res = await updateDoc(query, group);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getAllLessonsAndMovementBreaks() {
  try {
    var lessons = await getAllLessonsById();
    var movementBreaks = await getAllMovementBreaksById();

    var both = lessons.concat(movementBreaks);
    var filt = both.filter(
      (tag, index, array) =>
        array.findIndex((t) => t.Title === tag.Title) === index
    );
    return filt;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllLessonsById() {
  try {
    const dbRef = ref(database);
    return get(child(dbRef, `orderedLessonsById`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(Object.values(data));
          return Object.values(data);
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllMovementBreaksById() {
  try {
    const dbRef = ref(database);
    return get(child(dbRef, `orderedMovementBreaksById`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(Object.values(data));
          return Object.values(data);
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  } catch (e) {
    console.log(e);
    return [];
  }
}

const specialUnits = {
  halloween: [
    "39775790-123b-426a-88df-dbe1e3472cdc",
    "26139bdf-3f88-42d5-a1c5-4e950e497898",
    "a938c8a2-db51-4665-b912-8dfe98ffb553",
    "31bda152-d432-4600-a0c2-bd79974e2089",
  ],
  christmas: [
    "7cb37acb-3c8e-431e-b4e9-bf219e713013",
    "e828e211-b7b7-47db-9180-bcfb979693b8",
    "317fe5ea-7d8e-4a65-a36c-481fe3a97420",
    "e09299aa-7629-4d49-90f0-eb440170d368",
  ],
};

export async function getLessonOrMovementBreakIcon(unitId) {
  try {
    if (specialUnits.halloween.includes(unitId)) {
      return "Halloween";
    }
    if (specialUnits.christmas.includes(unitId)) {
      return "Christmas";
    }
    const units = await getAllUnits();
    var unit = units.filter((unit) => unit.Guid === unitId);
    if (unit.length === 0) {
      let mbs = await getMovementBreakCategories();
      mbs = mbs.filter((x) => x);
      unit = mbs.pop((unit) => unit.Guid === unitId);
      if (unit.length === 0) {
        return "Athletics";
      }
      return unit.Title;
    }
    const strands = await getAllStrands();
    const strand = strands.filter(
      (strand) => strand.Guid === unit[0].StrandGuid
    );
    if (strand.length === 0) {
      return "Athletics";
    }
    return strand[0].Title;
  } catch (e) {
    console.log(e);
    return "Athletics";
  }
}

export async function deleteStructuredLessonGroup(id, group) {
  try {
    let query = doc(firestoreDb, `structured-lessons/${id}/content`, group.id);
    let res = await deleteDoc(query);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadFileToStorage(path, file) {
  try {
    const imageRef = sRef(storage, `${path}/${file.name}`);
    let url = await uploadBytes(imageRef, file).then(async (snapshot) => {
      return await getDownloadURL(snapshot.ref).then(async (url) => {
        return url;
      });
    });
    return url;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadContentToDatabase(content, type, hash) {
  try {
    if (type === "Lessons") {
      var lessons = await getAllLessons();
      lessons = lessons.concat(content);
      const dbRef = ref(database);
      await update(child(dbRef, `lessons`), { ...lessons });
      await orderLessons();
      await orderLessonsById();
      await update(child(dbRef, `databaseChecksums/3/`), {
        Hash: hash.toString(),
      });
      return true;
    }
    if (type === "Movement Breaks") {
      var mb = await getAllMovementBreaks();
      const dbRef = ref(database);
      mb = mb.concat(content);
      await update(child(dbRef, `movementBreaks`), { ...mb });
      await orderMovementBreaks();
      await orderMovementBreaksById();
      await update(child(dbRef, `databaseChecksums/0/`), {
        Hash: hash.toString(),
      });
      return true;
    }

    return;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteContentFromDatabase(content, type) {
  let hash = new uuidv4().toString();

  try {
    if (type === "Lessons") {
      var lessons = await getAllLessons();
      var index = lessons.findIndex((o) => o && o.Guid === content.Guid);
      lessons.splice(index, 1);

      const dbRef = ref(database);
      await set(child(dbRef, `lessons/`), lessons);
      await remove(
        child(dbRef, `orderedLessons/${content.UnitGuid}/${content.Guid}`)
      );
      await remove(child(dbRef, `orderedLessonsById/${content.Guid}`));
      await update(child(dbRef, `databaseChecksums/3/`), { Hash: hash });
      return true;
    }
    if (type === "Movement Breaks") {
      var mb = await getAllMovementBreaks();
      const dbRef = ref(database);
      var index = mb.findIndex((o) => o && o.Guid === content.Guid);
      mb.splice(index, 1);
      await set(child(dbRef, `movementBreaks/`), mb);
      await remove(
        child(
          dbRef,
          `orderedMovementBreaks/${content.CategoryGuid}/${content.Year}/${content.Guid}`
        )
      );

      await remove(child(dbRef, `orderedMovementBreaksById/${content.Guid}`));
      await update(child(dbRef, `databaseChecksums/0/`), { Hash: hash });
      return true;
    }

    return;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function createFreePremiumAccount(
  couponCode,
  id,
  numberOfAccounts
) {
  if (couponCode !== "IPPN2023") {
    console.log(couponCode);
    return false;
  }

  const q = query(collection(firestoreDb, `customers/${id}/subscriptions`));

  let data = {
    free: true,
    date: new Date().toISOString(),
    status: "active",
    items: [
      {
        quantity: parseInt(numberOfAccounts),
        price: {
          unit_amount: 0,
          recurring: {
            interval: 0,
          },
        },
      },
    ],
  };

  return await addDoc(q, data)
    .then((ref) => {
      return ref;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function getLessonEquipmentById(lessonId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedLessonsById/${lessonId}/Equipment`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return "";
      }
    })
    .catch((error) => {
      console.error(error);
      return "";
    });
}

export async function getMovementBreakEquipmentById(lessonId) {
  const dbRef = ref(database);
  return get(child(dbRef, `orderedMovementBreaksById/${lessonId}/Equipment`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return "";
      }
    })
    .catch((error) => {
      console.error(error);
      return "";
    });
}

export async function updateStructuredLessonGroupOrder(
  structuredId,
  groupId,
  order
) {
  try {
    let query = doc(
      firestoreDb,
      `structured-lessons/${structuredId}/content`,
      groupId
    );
    let res = await updateDoc(query, { order: order });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function giveStructuredLessonFeedback(feedback) {
  try {
    let query = collection(
      firestoreDb,
      `structured-lessons/${feedback.content_group_id}/feedback/`
    );
    let res = await addDoc(query, feedback);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function lessonAnalytics(id, name, year, userId, userEmail) {
  logEvent(analytics, "select_content", {
    content_type: "lesson",
    content_id: id,
    content_name: name,
    user_year: year,
    user_id: userId,
    user_email: userEmail,
  });
}

export async function movementBreakAnalytics(
  id,
  name,
  year,
  userId,
  userEmail
) {
  logEvent(analytics, "select_content", {
    content_type: "movement_break",
    content_id: id,
    content_name: name,
    user_year: year,
    user_id: userId,
    user_email: userEmail,
  });
}

export async function structuredLessonAnalytics(
  id,
  name,
  year,
  userId,
  userEmail
) {
  logEvent(analytics, "select_content", {
    content_type: "structured_lesson",
    content_id: id,
    content_name: name,
    user_year: year,
    user_id: userId,
    user_email: userEmail,
  });
}

export async function structuredLessonWeekAnalytics(
  id,
  groupId,
  name,
  year,
  userId,
  userEmail
) {
  logEvent(analytics, "select_content", {
    content_type: "structured_lesson_week",
    content_id: id,
    content_group_id: groupId,
    content_name: name,
    user_year: year,
    user_id: userId,
    user_email: userEmail,
  });
}

export async function getAllAdminAccountDetails() {
  const dbRef = ref(database);
  return get(child(dbRef, `admin`))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        var cleanedData = [];
        const data = snapshot.val();

        Object.values(data).forEach((d) => {
          if ("PaidForCodes" in d) {
            cleanedData.push(d);
          }
        });

        let detailsList = [];

        cleanedData.forEach((user) => {
          detailsList.push(
            new Promise(async (resolve, reject) => {
              var email = await getUserEmail(user.Guid);
              resolve({
                school: user.SchoolName,
                email: email,
              });
            })
          );
        });

        let details = await Promise.all(detailsList).then((values) => {
          return values;
        });
        return details;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
}

export async function getAllLessonsAsTSV() {
  let lessons = await getAllLessons();
  let units = await getAllUnits();
  let strands = await getAllStrands();

  for (let i = 0; i < lessons.length; i++) {
    let unitId = lessons[i].UnitGuid;
    let unit = units.find((unit) => {
      return unit.Guid === unitId;
    });
    if (unit === undefined) {
      console.log(lessons[i].Title);
      console.log("Error with unit");
      continue;
    }
    let strand = strands.find((strand) => {
      return strand.Guid === unit.StrandGuid;
    });

    if (strand === undefined) {
      console.log(lessons[i].Title);
      console.log("Error with strand");
      continue;
    }

    lessons[i].Year = strand.Year;
    lessons[i].Strand = strand.Title;
    lessons[i].Unit = unit.Title;
  }

  let csvContent = "data:text/tsv;charset=utf-8,";
  csvContent +=
    "Guid\tTitle\tDescription\tEquipment\tUrl\tUrlEmbeded\tYear\tUnit\tStrand\tUnitGuid\r\n";

  lessons.forEach((lesson) => {
    if (lesson !== null && "Strand" in lesson) {
      csvContent +=
        lesson.Guid +
        "\t" +
        lesson.Title +
        "\t" +
        lesson.Description.replaceAll("\n", " ") +
        "\t" +
        lesson.Equipment +
        "\t" +
        lesson.Url +
        "\t" +
        lesson.UrlEmbeded +
        "\t" +
        lesson.Year +
        "\t" +
        lesson.Unit +
        "\t" +
        lesson.Strand +
        "\t" +
        lesson.UnitGuid +
        "\r\n";
    }
  });
  var encodedUri = encodeURI(csvContent);

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "lesson_details.tsv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

export async function getAllMovementBreaksAsTSV() {
  let mbs = await getAllMovementBreaks();
  let categories = await getMovementBreakCategories();

  for (let i = 0; i < mbs.length; i++) {
    let categoryId = mbs[i].CategoryGuid;
    let category = categories.find((category) => {
      return category !== undefined && category.Guid === categoryId;
    });
    if (category === undefined) {
      console.log(mbs[i].Title);
      console.log("Error with category");
      continue;
    }
    mbs[i].Category = category.Title;
  }

  let csvContent = "data:text/tsv;charset=utf-8,";
  csvContent +=
    "Guid\tTitle\tDescription\tEquipment\tUrl\tUrlEmbeded\tYear\tCategory\tCategoryGuid\r\n";

  mbs.forEach((mb) => {
    if (mb !== null && "Category" in mb) {
      csvContent +=
        mb.Guid +
        "\t" +
        mb.Title +
        "\t" +
        mb.Description.replaceAll("\n", " ") +
        "\t" +
        mb.Equipment +
        "\t" +
        mb.Url +
        "\t" +
        mb.UrlEmbeded +
        "\t" +
        mb.Year +
        "\t" +
        mb.Category +
        "\t" +
        mb.CategoryGuid +
        "\r\n";
    }
  });
  var encodedUri = encodeURI(csvContent);

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "movement_break_details.tsv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

export async function getStrand(id) {
  const dbRef = ref(database);
  let strands = await getAllStrands();
  return strands.find((strand) => {
    return strand.Guid === id;
  });
}

export async function sendDataRequestEmail(
  accountId,
  accountEmail,
  senderEmail,
  requestType,
  additionalInfo
) {
  try {
    let query = collection(firestoreDb, "mail");
    let docRef = await addDoc(query, {
      to: "burkejohn1999@gmail.com",
      message: {
        subject: "Daly Exercise+ Data Request",
        html: `Hi.
        <br/>
        <br/>
        ${requestType} notification.
        <br/>
        <br/>
        Account ID: ${accountId}.
        <br/>
        <br/>
        Account Email: ${accountEmail}.
        <br/>
        <br/>
        Sender ID: ${senderEmail}.
        <br/>
        <br/>
        Additional Info: ${additionalInfo}.
        <br/>
        <br/>
        This is an automated email.`,
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// Once off functions

export async function addEmailsToAdmins() {
  const dbRef = ref(database);
  let admins = await get(child(dbRef, `admin/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

  let users = await get(child(dbRef, `users/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

  Object.keys(admins).forEach((key) => {
    let email = users[key].UserName;
    admins[key].Email = email;
  });

  await set(child(dbRef, `admin/`), admins);
}

export async function addSubscriptionTypeToAdmins() {
  const dbRef = ref(database);
  let admins = await get(child(dbRef, `admin/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

  let adminPromises = [];

  Object.keys(admins).forEach((key) => {
    adminPromises.push(
      getSubscriptionDetails(key).then((subDetails) => {
        // console.log(key + " " + subDetails, )
        if (subDetails == "") {
          admins[key].Status = "No Subscription";
        } else {
          admins[key].HasCouponCode = subDetails.isFree
            ? subDetails.isFree
            : false;
          admins[key].Status = subDetails.status ? subDetails.status : "Error";
          admins[key].SubscriptionId = subDetails.id;
          admins[key].Created = subDetails.isFree
            ? new Date(subDetails.created).toDateString()
            : new Date(subDetails.created?.toDate()).toDateString();
        }
      })
    );
  });

  await Promise.all(adminPromises);

  console.log("Data Ready");
  console.log(admins);

  await set(child(dbRef, `admin/`), admins);

  console.log("Data Uploaded");
}

export async function getAdminsDetailsForAnalytics() {
  const dbRef = ref(database);
  let admins = await get(child(dbRef, `admin/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

  let trialAccounts = [];
  let paidAccounts = [];
  let couponAccounts = [];

  Object.values(admins).forEach((admin) => {
    if (admin.Status === "trialing") {
      trialAccounts.push(admin);
    } else if (admin.HasCouponCode === true && admin.Status === "active") {
      couponAccounts.push(admin);
    } else if (admin.Status === "active") {
      paidAccounts.push(admin);
    }
  });

  return {
    trial: trialAccounts,
    coupon: couponAccounts,
    paid: paidAccounts,
  };
}

export async function removeSubscriptionFromUser(account) {
  const dbRef = ref(database);

  const q = query(
    doc(
      firestoreDb,
      `customers/${account.Guid}/subscriptions/${account.SubscriptionId}`
    )
  );

  let data = {
    status: "deactivated",
  };

  let res = await updateDoc(q, data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });

  if (!res) {
    return false;
  }

  await update(child(dbRef, `admin/${account.Guid}`), {
    Status: "Deactivated",
  });

  return true;
}

export async function getTeacherDetailsFromAdminList(adminList) {
  const dbRef = ref(database);
  let teachers = await get(child(dbRef, `users/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return Object.values(data);
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

  let fullList = [];

  adminList.forEach((admin) => {
    let adminCodes = admin.InviteCodes;

    if (adminCodes) {
      fullList.push({
        ...admin,
        IsAdmin: true,
      });

      adminCodes.forEach((code) => {
        let teacher = teachers.find((element) => element.InviteCode === code);
        if (teacher) {
          if (teacher.UserName !== admin.Email) {
            fullList.push({
              ...admin,
              Email: teacher.UserName,
              IsAdmin: false,
            });
          }
        }
      });
    }
  });

  return fullList;
}

export async function changeSubscriptionQuantity(
  guid,
  subsctiptionId,
  quantity
) {
  const q = query(
    doc(firestoreDb, `customers/${guid}/subscriptions/${subsctiptionId}`)
  );

  let data = {
    items: [
      {
        quantity: parseInt(quantity),
        price: { recurring: { interval: 0 }, unit_amount: 0 },
      },
    ],
    date_changed: new Date().toDateString(),
  };

  return await updateDoc(q, data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function getAllStrandsNew() {
  try {
    const q = query(collection(firestoreDb, `strands`));
    let snapshot = await getDocs(q);
    let strands = snapshot.docs
      .map((doc) => doc.data())
      .filter((data) => data.Type === "Lesson");

    return strands;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllLessonsNew() {
  try {
    const q = query(collection(firestoreDb, `lessons`));
    let snapshot = await getDocs(q);
    let lessons = snapshot.docs.map((doc) => doc.data());
    return lessons;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllCategoriesNew() {
  try {
    const q = query(collection(firestoreDb, `categories`));
    let snapshot = await getDocs(q);
    let categories = snapshot.docs.map((doc) => doc.data());
    return categories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllSubcategoriesNew() {
  try {
    const q = query(collection(firestoreDb, `subcategories`));
    let snapshot = await getDocs(q);
    let subcategories = snapshot.docs.map((doc) => doc.data());
    return subcategories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getStrandNew(id) {
  try {
    const q = query(doc(firestoreDb, `strands/${id}`));
    let snapshot = await getDoc(q);
    let strand = snapshot.data();
    return strand;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getSubcategoryLessons(lessonIds, year) {
  try {
    let subLists = [];
    for (let i = 0; i < lessonIds.length; i += 25) {
      subLists.push(lessonIds.slice(i, i + 25));
    }
    let queries = [];
    subLists.forEach((subList) => {
      queries.push(
        query(
          collection(firestoreDb, `lessons`),
          where("Guid", "in", subList),
          where("Year", "==", year)
        )
      );
    });

    let queryPromises = [];
    queries.forEach((q) => {
      queryPromises.push(
        getDocs(q).then((snap) => snap.docs.map((doc) => doc.data()))
      );
    });
    let allLessons = await Promise.all(queryPromises);
    return allLessons.flat(1);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllSubcategoryLessons(lessonIds) {
  try {
    let subLists = [];
    for (let i = 0; i < lessonIds.length; i += 25) {
      subLists.push(lessonIds.slice(i, i + 25));
    }
    let queries = [];
    subLists.forEach((subList) => {
      queries.push(
        query(collection(firestoreDb, `lessons`), where("Guid", "in", subList))
      );
    });

    let queryPromises = [];
    queries.forEach((q) => {
      queryPromises.push(
        getDocs(q).then((snap) => snap.docs.map((doc) => doc.data()))
      );
    });
    let allLessons = await Promise.all(queryPromises);
    return allLessons.flat(1);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getStrandCategories(categoryIds) {
  try {
    const q = query(
      collection(firestoreDb, `categories`),
      where("Guid", "in", categoryIds)
    );
    let snapshot = await getDocs(q);
    let categories = snapshot.docs.map((doc) => doc.data());
    return categories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getSubcategories(subcategoryIds) {
  try {
    const q = query(
      collection(firestoreDb, `subcategories`),
      where("Guid", "in", subcategoryIds)
    );
    let snapshot = await getDocs(q);
    let subcategories = snapshot.docs.map((doc) => doc.data());
    return subcategories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function uploadAllLessonsNew() {
  let lessons = warmUpVideos;
  let lessonsPromise = [];
  lessons.forEach((lesson) => {
    [0, 1, 2, 3].forEach((i) => {
      let itemId = uuidv4().toString();
      let newLesson = {
        ...lesson,
        Guid: itemId,
        Year: i,
        Resources: "",
      };

      let q = query(doc(firestoreDb, `lessons/${newLesson.Guid}`));
      lessonsPromise.push(setDoc(q, newLesson));
    });
  });

  await Promise.all(lessonsPromise);
  console.log("Finished");
}

export async function uploadAllCategoriesNew() {
  let categories = tempCategoryData;
  let categoryPromises = [];
  categories.forEach((category) => {
    if (category.Guid !== "" && category.Guid !== undefined) {
      let q = query(doc(firestoreDb, `categories/${category.Guid}`));
      categoryPromises.push(setDoc(q, category));
    }
  });

  await Promise.all(categoryPromises);
  console.log("Finished");
}

export async function uploadAllSubCategoriesNew() {
  let subcategories = tempSubcategoryData;
  let subcategoryPromises = [];
  subcategories.forEach((subcategory) => {
    if (subcategory.Guid !== "" && subcategory.Guid !== undefined) {
      let q = query(doc(firestoreDb, `subcategories/${subcategory.Guid}`));
      subcategoryPromises.push(setDoc(q, subcategory));
    }
  });

  await Promise.all(subcategoryPromises);
  console.log("Finished");
}

export async function updateStrand(strand) {
  try {
    const q = query(doc(firestoreDb, `strands/${strand.Guid}`));
    await updateDoc(q, strand);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadStrand(strand) {
  try {
    const strandId = uuidv4().toString();
    strand.Guid = strandId;
    const q = query(doc(firestoreDb, `strands/${strandId}`));
    await setDoc(q, strand);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteStrand(strand) {
  try {
    const q = query(doc(firestoreDb, `strands/${strand.Guid}`));
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getCategoryNew(id) {
  try {
    const q = query(doc(firestoreDb, `categories/${id}`));
    let snapshot = await getDoc(q);
    let category = snapshot.data();
    return category;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function updateCategory(category, newStrand, oldStrand) {
  try {
    const q = query(doc(firestoreDb, `categories/${category.Guid}`));
    await updateDoc(q, category);

    if (newStrand.Guid !== oldStrand.Guid) {
      newStrand.Categories.push(category.Guid);
      let ind = oldStrand.Categories.indexOf(category.Guid);
      oldStrand.Categories.splice(ind, 1);

      await updateStrand(newStrand);
      await updateStrand(oldStrand);
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function updateCategoryNoStrand(category) {
  try {
    const q = query(doc(firestoreDb, `categories/${category.Guid}`));
    await updateDoc(q, category);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteCategory(category) {
  try {
    const q = query(doc(firestoreDb, `categories/${category.Guid}`));
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadCategory(category, strand) {
  try {
    const categoryId = uuidv4().toString();
    category.Guid = categoryId;
    const q = query(doc(firestoreDb, `categories/${categoryId}`));
    await setDoc(q, category);
    strand.Categories.push(categoryId);
    await updateStrand(strand);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getSubCategoryNew(id) {
  try {
    const q = query(doc(firestoreDb, `subcategories/${id}`));
    let snapshot = await getDoc(q);
    let subcategory = snapshot.data();
    return subcategory;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function updateSubCategory(subcategory, newCategory, oldCategory) {
  try {
    const q = query(doc(firestoreDb, `subcategories/${subcategory.Guid}`));
    await updateDoc(q, subcategory);

    if (newCategory.Guid !== oldCategory.Guid) {
      newCategory.SubCategories.push(subcategory.Guid);
      let ind = oldCategory.SubCategories.indexOf(subcategory.Guid);
      oldCategory.SubCategories.splice(ind, 1);

      await updateStrand(newCategory);
      await updateStrand(oldCategory);
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteSubCategory(subcategory) {
  try {
    const q = query(doc(firestoreDb, `subcategories/${subcategory.Guid}`));
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadSubCategory(subcategory, category) {
  try {
    const subcategoryId = uuidv4().toString();
    subcategory.Guid = subcategoryId;
    const q = query(doc(firestoreDb, `subcategories/${subcategoryId}`));
    await setDoc(q, subcategory);
    category.SubCategories.push(subcategoryId);
    await updateCategoryNoStrand(category);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getLessonNew(id) {
  try {
    const q = query(doc(firestoreDb, `lessons/${id}`));
    let snapshot = await getDoc(q);
    let lesson = snapshot.data();
    return lesson;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function updateLesson(lesson) {
  try {
    const q = query(doc(firestoreDb, `lessons/${lesson.Guid}`));
    await updateDoc(q, lesson);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteLessonNew(lesson) {
  try {
    const q = query(doc(firestoreDb, `lessons/${lesson.Guid}`));
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadLessonNew(lesson) {
  try {
    const lessonId = uuidv4().toString();
    lesson.Guid = lessonId;
    const q = query(doc(firestoreDb, `lessons/${lessonId}`));
    await setDoc(q, lesson);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getAllMovementBreakStrands() {
  try {
    const q = query(collection(firestoreDb, `strands`));
    let snapshot = await getDocs(q);
    let strands = snapshot.docs
      .map((doc) => doc.data())
      .filter((data) => data.Type === "Movement Break");

    return strands;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllStrandTypes() {
  try {
    const q = query(collection(firestoreDb, `strands`));
    let snapshot = await getDocs(q);
    let strands = snapshot.docs.map((doc) => doc.data());

    return strands;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function uploadToScheduleNew(item, userId, date) {
  try {
    const itemId = uuidv4().toString();
    const q = query(
      doc(firestoreDb, `users/${userId}/schedule/${date}/items/${itemId}`)
    );
    item.Guid = itemId;
    await setDoc(q, item);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteFromScheduleNew(itemId, userId, date) {
  try {
    const q = query(
      doc(firestoreDb, `users/${userId}/schedule/${date}/items/${itemId}`)
    );
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getDateSchedule(userId, date) {
  try {
    const q = query(
      collection(firestoreDb, `users/${userId}/schedule/${date}/items`)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((doc) => doc.data());

    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteAllLessonVideos() {
  let lessons = tempDataLessons;
  let lessonsPromise = [];
  lessons.forEach((lesson) => {
    if (lesson.Guid !== "" && lesson.Guid !== undefined) {
      let q = query(doc(firestoreDb, `lessons/${lesson.Guid}`));
      lessonsPromise.push(deleteDoc(q));
    }
  });

  await Promise.all(lessonsPromise);
  console.log("Finished");
}

export async function fixNewLessonsEmbededVideoLinks() {
  let lessons = await getAllLessonsNew();
  let promises = [];
  lessons.forEach((lesson) => {
    if ("UrlEmbed" in lesson) {
      let embed = lesson.UrlEmbed;
      delete lesson.UrlEmbed;
      let updatedLesson = {
        ...lesson,
        UrlEmbeded: embed,
      };
      const q = query(doc(firestoreDb, `lessons/${lesson.Guid}`));
      promises.push(setDoc(q, updatedLesson));
    }
  });

  await Promise.all(promises);
  console.log("Finished");
}

export async function uploadIconsAndColoursToLessons() {
  let strands = await getAllStrandsNew();
  let categories = await getAllCategoriesNew();
  let subcategories = await getAllSubcategoriesNew();
  let updatePromises = [];
  let count = 0;
  console.log("Before");
  strands.forEach((strand) => {
    if (strand.Title === "Christmas PE Games" && "Categories" in strand) {
      strand.Categories.forEach((category) => {
        let cat = categories.find((cat) => cat.Guid === category);
        if (cat !== undefined) {
          cat.SubCategories.forEach((subcategory) => {
            let subcat = subcategories.find((sub) => sub.Guid === subcategory);
            if (subcat !== undefined) {
              subcat.Lessons.forEach((lessonId) => {
                if (lessonId !== undefined) {
                  const q = query(doc(firestoreDb, `lessons/${lessonId}`));
                  updatePromises.push(
                    updateDoc(q, {
                      Icon: strand.ImageUri,
                      Colour: strand.ColourName,
                    }).catch((e) => console.log("Error: " + lessonId))
                  );
                  count += 1;
                }
              });
            }
          });
        }
      });
    }
  });
  await Promise.all(updatePromises);
  console.log(count);
}

export async function getLessonEquipmentNew(id) {
  try {
    const q = query(doc(firestoreDb, `lessons/${id}`));
    console.log(id);
    let snapshot = await getDoc(q);
    let lesson = snapshot.data().Equipment;
    return lesson;
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function getUserDataNew(id) {
  try {
    const q = query(doc(firestoreDb, `users/${id}`));
    let snapshot = await getDoc(q);
    let details = snapshot.data();
    return details;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getExactSubscription(ref) {
  return getDoc(ref)
    .then((snapshot) => {
      let doc = snapshot.data();
      let docId = snapshot.id;
      return {
        id: docId,
        status: doc.status,
        quantity: doc.items[0].quantity,
        price: doc.items[0].price.unit_amount,
        interval: doc.items[0].price.recurring.interval,
        created: doc.date ? doc.date : doc.created,
        isFree: doc.free,
        nextPaymentDue: doc.current_period_end,
      };
    })
    .catch((error) => {
      console.log(error);
      return {};
    });
}

export async function openSubscriptionManagement() {
  const manageSub = httpsCallable(
    functions,
    "ext-firestore-stripe-subscriptions-createPortalLink"
  );
  try {
    const { data } = await manageSub({
      returnUrl: window.location.href,
      locale: "auto",
    });
    return window.open(data.url, "_blank");
  } catch (e) {
    // console.log(e);
    return "";
  }
}
