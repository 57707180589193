import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Stack,
  Modal,
  Accordion,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ReactPlayer from "react-player";
import { getEmptyWorkout } from "../../models/workout";
import {
  addWorkoutToClassroom,
  getAllExercisesByYearAndOrderCategory,
  getAllExercisesOrderCategory,
  getExerciseUrl,
  getRandomExercisesByYear,
} from "../../functions/workoutFunctions";
import ExerciseCustomBox from "../../components/ExerciseCustomBox";

function CustomWorkoutPage() {
  const [homeworkName, setHomeworkName] = useState("");
  const [submittedName, setSubmittedName] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [nameError, setNameError] = useState("");
  const [exercises, setExercises] = useState([]);
  const [allExercises, setAllExercises] = useState([]);
  const [loadingExercises, setLoadingExercises] = useState(false);

  const [numberOfSets, setNumberOfSets] = useState(1);
  const [showExercise, setShowExercise] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const [gifUrl, setGifUrl] = useState("");

  const [selectedExerciseCount, setSelectedExerciseCount] = useState({});

  const handleSets = () => {
    setNumberOfSets((numberOfSets % 3) + 1);
  };
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  const handleValidation = () => {
    let formIsValid = true;

    if (homeworkName === "") {
      setNameError("Please enter a name for your homework");
      formIsValid = false;
    }

    return formIsValid;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Custom";
    if (data2.Year === 10 || data2.Year === 11) {
      getAllExercisesOrderCategory().then((res) => {
        res = {
          "Adapted Physical Exercisesres": res["Adapted Physical Exercises"]
        }
        const categories = Object.keys(res);
        let selectedExerciseCount = {};
        categories.forEach((cat) => {
          console.log(cat)
          selectedExerciseCount[cat] = 0;
        });
        setSelectedExerciseCount(selectedExerciseCount);
        setAllExercises(res);
      });
    } else {
      getAllExercisesOrderCategory().then((res) => {
        const categories = Object.keys(res);
        let selectedExerciseCount = {};
        categories.forEach((cat) => {
          selectedExerciseCount[cat] = 0;
        });
        setSelectedExerciseCount(selectedExerciseCount);
        setAllExercises(res);
      });
    }
  }, [data2]);

  const submitRandom = async () => {
    if (handleValidation()) {
      setLoadingExercises(true);
      let random = await getRandomExercisesByYear(
        data2.Year,
        numberOfExercises
      );
      setSubmittedName(homeworkName);
      setExercises(random);
    }
    setLoadingExercises(false);
  };

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const saveWorkout = async () => {
    let workout = getEmptyWorkout();
    workout = {
      ...workout,
      Thumbnail: `homework/thumbnails/${exercises[0].GifUri}.jpg`,
      Name: homeworkName,
      Exercises: exercises.map((e) => e.Guid),
      Sets: numberOfSets,
    };

    await addWorkoutToClassroom(data2.Guid, workout);
    navigate("/workouts/saved");
  };

  const exerciseClicked = (exercise, category) => {
    if (exercises.includes(exercise)) {
      setExercises(exercises.filter((e) => e !== exercise));
      let newSelectedExerciseCount = {
        ...selectedExerciseCount,
      };
      newSelectedExerciseCount[category] -= 1;
      setSelectedExerciseCount(newSelectedExerciseCount);
    } else {
      let newSelectedExerciseCount = {
        ...selectedExerciseCount,
      };
      newSelectedExerciseCount[category] += 1;
      setSelectedExerciseCount(newSelectedExerciseCount);
      exercises.push(exercise);
    }
  };

  return (
    <Container fluid>
      <Modal
        show={showExercise}
        onHide={handleCloseExercise}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {exerciseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactPlayer url={gifUrl} loop={true} playing={true} muted={true} className="exercise-preview-video" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExercise}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Custom Workout</h1>
          <div className="white-box">
            <Stack direction="horizontal">
              <h3 className="title-margin page-title">
                Create your own workout
              </h3>
              <h4 className="ms-auto">{submittedName}</h4>
            </Stack>
          </div>

          <Row>
            <Col md={8}>
              <div className="white-box equal-height">
                {loadingExercises ? (
                  <div className="custom-loader" />
                ) : Object.keys(allExercises) === 0 ? (
                  <p>Loading</p>
                ) : (
                  <Accordion>
                    {Object.entries(allExercises).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map((x, i) => {
                      return (
                        <Accordion.Item key={i} eventKey={i}>
                          <Accordion.Header className="headingColour">
                            ({selectedExerciseCount[x[0]]}) {x[0]}
                          </Accordion.Header>
                          <Accordion.Body>
                            {Object.values(x[1])
                              .sort((a, b) => a.Name.localeCompare(b.Name))
                              .map((exercise, j) => {
                                return (
                                  <ExerciseCustomBox
                                    exercise={exercise}
                                    category={x[0]}
                                    key={j}
                                    PreviewExercise={changeSelectedExercise}
                                    AddExercise={exerciseClicked}
                                  />
                                );
                              })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            </Col>

            <Col md={4}>
              <div className="white-box equal-height">
                <h4>Fill out to view a preview!</h4>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Workout Name</Form.Label>
                    <Form.Control
                      placeholder="Enter name"
                      onChange={(event) => setHomeworkName(event.target.value)}
                    />
                    <Form.Text id="homeworkNameHelpBlock" muted>
                      {nameError}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Number of Reps</Form.Label>
                    <Form.Select
                      onChange={(event) => setNumberOfSets(event.target.value)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </Form.Select>
                  </Form.Group>
                  <hr />
                  <div className="d-grid">
                    <button
                      onClick={saveWorkout}
                      className="custom-button-big"
                      type="button"
                    >
                      Generate Workout
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default CustomWorkoutPage;
