import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions, getSubscriptionDetails } from "../firebase";
import { getEmptyAccount } from "../models/account";
import { getSchoolSubscription } from "./subscriptionFunctions";

export async function getAnalyticalData() {
  try {
    const callable = httpsCallable(functions, "getSchoolAnalyticalData");
    let response = await callable();
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function downloadAnalyticalDataAsCSV(
  data = null,
  filename = "marketing_data.csv"
) {
  if (data === null) {
    data = await getAnalyticalData();
  }

  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent +=
    "SchoolName,SchoolRoll,Email,IsAdmin,SubscriptionStatus,SubscriptionCreationData,SubscriptionPeriodStart,SubscriptionPeriodEnd,PayByInvoice\r\n";

  data.forEach((row) => {
    if (row !== null) {
      csvContent +=
        (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
        "," +
        (row.SchoolRoll ? row.SchoolRoll.replaceAll(",", " ") : "") +
        "," +
        row.Email +
        "," +
        row.IsAdmin +
        "," +
        (row.SubscriptionType ? row.SubscriptionType : "none") +
        "," +
        (row.PayByInvoice
          ? new Date(row.InvoiceDate).toLocaleDateString()
          : row.SubStart
          ? toDateTime(row.SubStart._seconds)
          : "") +
        "," +
        (row.CurrentSubStart ? toDateTime(row.CurrentSubStart._seconds) : "") +
        "," +
        (row.CurrentSubEnd ? toDateTime(row.CurrentSubEnd._seconds) : "") +
        "," +
        (row.PayByInvoice ? row.PayByInvoice : false) +
        "\r\n";
    }
  });
  var encodedUri = encodeURI(csvContent);

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Required for FF

  link.click();
}

function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t.toLocaleDateString();
}

export async function deleteEmptySchools() {
  try {
    const callable = httpsCallable(functions, "deleteEmptySchools");
    let response = await callable();
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function sendPixelCheckoutEvent(
  userID,
  quantity,
  interval,
  subscriptionID,
  price
) {
  try {
    const data = {
      userID: userID,
      quantity: quantity,
      interval: interval,
      subscriptionID: subscriptionID,
      price: price,
    };
    const callable = httpsCallable(functions, "sendPixelCheckoutEvent");
    let response = await callable(data);
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function localPixelCheckoutEvent(
  userID,
  quantity,
  interval,
  subscriptionID,
  price
) {
  try {
    window.fbq &&
      window.fbq("track", "InitiateCheckout", {
        value: price * quantity,
        currency: "EUR",
        content_ids: [subscriptionID],
        content_name: "Subscription",
        price: price,
        user_id: userID,
        interval: interval,
      });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function localPixelPurchaseEvent(userID) {
  try {
    const userData = await getSubscriptionDetails(userID);

    window.fbq &&
      window.fbq("track", "Purchase", {
        value: (userData.price / 100) * userData.quantity,
        currency: "EUR",
        content_ids: [userData.subscriptionID],
        content_name: "Subscription",
        price: userData.price / 100,
        user_id: userID,
        interval: userData.interval,
        purchase_type: "new",
      });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
