import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions, storage } from "../firebase";
import { getEmptySchool } from "../models/school";
import { updateSubscriptionAdminUsers } from "./subscriptionFunctions";
import { getDownloadURL, ref as sRef } from "firebase/storage";

export async function getClassroomWorkouts(teacherID) {
  try {
    const q = collection(firestoreDb, `users/${teacherID}/workouts`);
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });

    let dataPromises = [];

    docs.forEach((doc) => {
      dataPromises.push(getWorkoutData(doc.Guid));
    });

    return await Promise.all(dataPromises);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getWorkoutData(workoutID) {
  try {
    const q = doc(firestoreDb, `workouts/${workoutID}`);
    let snap = await getDoc(q);
    if (!snap.exists) {
      return {};
    }
    return snap.data();
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function addWorkoutToClassroom(teacherID, workout) {
  try {
    const id = uuidv4().toString();
    workout = {
      ...workout,
      Guid: id,
    };

    const workoutLink = {
      Guid: id,
    };
    let q = doc(firestoreDb, `users/${teacherID}/workouts/${id}`);
    await setDoc(q, workoutLink);

    q = doc(firestoreDb, `workouts/${id}`);
    await setDoc(q, workout);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getRandomExercisesByYear(year, numOfRandom) {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("Year", "==", year)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs.sort((a, b) => 0.5 - Math.random()).slice(0, numOfRandom);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getRandomExercises(numOfRandom) {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("New", "==", true)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs.sort((a, b) => 0.5 - Math.random()).slice(0, numOfRandom);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getExerciseUrl(name) {
  try {
    return await getDownloadURL(sRef(storage, name));
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function getExercises(exerciseIDs) {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("Guid", "in", exerciseIDs)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllExercisesByYear(year) {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("Year", "==", year)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllExercisesByYearAndOrderCategory(year) {
  try {
    let exercises = await getAllExercisesByYear(year);
    let categories = {};
    exercises.forEach((exercise) => {
      if (categories.hasOwnProperty(exercise.Category)) {
        categories[exercise.Category].push(exercise);
      } else {
        categories[exercise.Category] = [exercise];
      }
    });
    return categories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteWorkout(workoutID, teacherID) {
  try {
    let q = doc(firestoreDb, `users/${teacherID}/workouts/${workoutID}`);
    await deleteDoc(q);

    q = doc(firestoreDb, `workouts/${workoutID}`);
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getAllExercises() {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("New", "==", true)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllExercisesOrderCategory() {
  try {
    let exercises = await getAllExercises();
    let categories = {};
    exercises.forEach((exercise) => {
      if (categories.hasOwnProperty(exercise.Category)) {
        categories[exercise.Category].push(exercise);
      } else {
        categories[exercise.Category] = [exercise];
      }
    });
    return categories;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const uploadExercises = async (exercises) => {
  try {
    for (const exercise of exercises) {
      const exerciseRef = doc(firestoreDb, "exercises", exercise.Guid);
      
      await setDoc(exerciseRef, {
        Category: exercise.Category,
        Name: exercise.Name,
        New: true, // Adding "New": true
        Guid: exercise.Guid,
        GifUri: exercise.GifUri
      });

      console.log(`Uploaded: ${exercise.Name} (${exercise.Guid})`);
    }
    console.log("All exercises uploaded successfully!");
  } catch (error) {
    console.error("Error uploading exercises:", error);
  }
};
